import { useRef } from 'react';
import { sortBy } from 'lodash';
import { Select } from 'antd5';
import { BaseSelectRef } from 'rc-select';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { Button } from 'components/ui';
import {
  CustomFilterPopover,
  CustomFilterContainer,
  SelectContainer,
} from 'components/data/helpers/filters.style';
import { useGetSitesMinimalQuery } from 'types/atlas-graphql';
import { filterOption } from 'components/choosers/helpers';
import { AutoFocus } from 'horizon/components/AutoFocus';
import { CustomFilterDropdownProps } from 'horizon/types/TableColumnDef';

export const SiteFilter: React.FunctionComponent<CustomFilterDropdownProps> = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
}) => {
  const inputRef = useRef<BaseSelectRef>(null);
  const siteIds = selectedKeys || [];

  const { data: sitesData, loading: sitesLoading } = useGetSitesMinimalQuery();
  const sites = sortBy(sitesData?.sites?.items, ['name']) ?? [];

  const handleChange = (value: string[]) => {
    setSelectedKeys(value);
  };

  const handleConfirm = () => {
    confirm({ closeDropdown: true });
  };

  const handleReset = () => {
    clearFilters && clearFilters();
  };

  return (
    <AutoFocus elementToFocus={inputRef}>
      <CustomFilterPopover className="ant-table-filter-dropdown">
        <CustomFilterContainer>
          <SelectContainer style={{ paddingRight: '44px', width: '240px' }}>
            <Select
              id="site-filter-dropdown"
              ref={inputRef}
              placeholder={'Select Site...'}
              loading={sitesLoading}
              allowClear={true}
              showSearch={true}
              filterOption={filterOption}
              virtual={false}
              value={sitesLoading ? undefined : siteIds}
              onChange={handleChange}
              mode={'multiple'}
            >
              {sites.map(site => (
                <Select.Option key={site.id} className="site-filter-option" value={site.id}>
                  {site.name}
                </Select.Option>
              ))}
            </Select>
          </SelectContainer>
        </CustomFilterContainer>
        <div className="ant-table-filter-dropdown-btns" style={{ borderTop: '1px solid #f0f0f0' }}>
          <Button type="link" size="small" onClick={handleReset}>
            Reset
          </Button>
          <Button type="primary" size="small" onClick={handleConfirm}>
            OK
          </Button>
        </div>
      </CustomFilterPopover>
    </AutoFocus>
  );
};
