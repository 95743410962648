import { Select } from 'antd';
import { useFeatures } from 'utils/features';
import { useDeliveryCustomerOrgOptions } from 'utils/organization';

interface IDeliveryOrganizationChooserProps {
  onChange?: any;
  testId?: string;
}

export const placeholderString = 'Select Organization for Inspection Delivery...';

export const DeliveryOrganizationChooser: React.FunctionComponent<
  IDeliveryOrganizationChooserProps
> = ({ onChange, testId }) => {
  const { INSPECTION_DELIVERY } = useFeatures().features;

  const { deliveryCustomerOptions, loading } = useDeliveryCustomerOrgOptions({
    skip: !INSPECTION_DELIVERY,
    sort: true,
  });

  return (
    <Select
      data-testid={testId}
      id="trusted-organization-chooser"
      placeholder={placeholderString}
      allowClear
      showSearch
      optionFilterProp="children"
      onChange={onChange}
    >
      {!loading && (
        <>
          {/* @ts-ignore null on this option will allow the user to deselect a campaign */}
          <Select.Option key="no-work-container" value={null}>
            None
          </Select.Option>
          {deliveryCustomerOptions?.map(deliveryCustomer => (
            <Select.Option key={deliveryCustomer.value} value={deliveryCustomer.value}>
              {deliveryCustomer.label}
            </Select.Option>
          ))}
        </>
      )}
    </Select>
  );
};
