import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { LOGO_ICON, LOGO_FULL } from 'utils/constants';
import { Tab } from './types';
import { StyledMenu, StyledSider, Logo, Image } from './SideNav.style';

interface Props {
  tabs: Tab[];
}

export const SideNav = ({ tabs }: Props) => {
  const [collapsed, setCollapsed] = useState<boolean | undefined>(undefined);
  const { pathname } = useLocation();

  const activeTab = tabs
    .filter(({ link, subRoutes = [] }) => {
      const routes = [link, ...subRoutes];
      return !isEmpty(routes.filter(route => pathname.startsWith(route)));
    })
    .map(({ id }) => id);

  const handleCollapse = () => {
    setCollapsed(prevState => !prevState);
  };

  useEffect(() => {
    // let antd handle collapse for < lg breakpoint
    if (window.innerWidth < 992) return;

    // collapse sidebar when analytics is active
    setCollapsed(pathname.startsWith('/analytics'));
  }, [pathname, setCollapsed]);

  return (
    <StyledSider
      breakpoint="lg"
      collapsed={collapsed}
      data-testid="sidebar-nav"
      onCollapse={handleCollapse}
      theme="light"
    >
      <Logo>
        <Link to="/">
          <Image alt="Logo" collapsed={collapsed} src={collapsed ? LOGO_ICON : LOGO_FULL} />
        </Link>
      </Logo>
      <StyledMenu
        selectedKeys={activeTab}
        style={{
          padding: collapsed ? '1em 0' : '1em',
          borderRight: 'none',
        }}
        items={tabs.map(({ id, title, icon, link }) => {
          // Allow components to soft-reload themselves if navigating to the current path
          const isSelfLink = link === pathname;
          return {
            label: (
              <Link replace={isSelfLink} to={{ pathname: link, state: { softReload: isSelfLink } }}>
                {title}
              </Link>
            ),
            icon,
            key: id,
            'data-testid': id,
          };
        })}
      />
    </StyledSider>
  );
};
