import { useEffect, useState } from 'react';
import { Input, Form } from 'antd5';

import { Button, Modal, useMessage } from 'components/ui';
import { TaskAssetPicker } from 'components/choosers';

import { useFeatures } from 'utils/features';
import { REPAIR_TASK, INSPECT_TASK, INTERNAL_BLADE_INSPECTION_TASK } from 'utils/constants';

import { useCreateTaskFromTemplateMutation } from 'types/atlas-graphql';
import { TAssetPickerResponse } from 'components/choosers/types';
import {
  getRefetchQueriesForTaskCreation,
  getTaskTargetInput,
  messageContent,
} from 'components/CreateTask/helpers';
import { useApolloContext } from 'utils/apollo';

function isRepairOrInspect(type: string | undefined) {
  if (type === REPAIR_TASK || type === INSPECT_TASK) return true;
  return false;
}

interface ITaskFromTemplateFormProps {
  title: string;
  visible: boolean;
  handleClose: any;
  templateId: string;
  parentId?: string;
  type: string | undefined;
  damageBranchId?: string;
  horizonDamageId?: string;
  inspectionId?: string;
  assetId?: string;
  queriesToRefetch?: string[];
}

// Trevor: We're currently getting this warning when opening this modal:
// Warning: Cannot update a component (`TaskFromTemplateForm`) while rendering a different component (`TaskAssetPicker`).
// Not entirely sure how to correct it, I didn't see anything obvious.
export const TaskFromTemplateForm: React.FunctionComponent<ITaskFromTemplateFormProps> = ({
  title,
  visible,
  handleClose,
  templateId,
  parentId,
  type,
  damageBranchId,
  horizonDamageId,
  inspectionId,
  assetId = '',
  queriesToRefetch = [],
}) => {
  const message = useMessage();
  const { papiClient } = useApolloContext();
  const { CREATE_OTHER_SITE_TASK } = useFeatures().features;

  const [form] = Form.useForm();
  const [creatingTask, setCreatingTask] = useState(false);
  const [selectedAssetId, setSelectedAssetId] = useState<string | undefined>(undefined);
  const [initialAssetId, setInitialAssetId] = useState<string | undefined>(assetId);

  const queryRefetch = getRefetchQueriesForTaskCreation({ horizonDamageId, damageBranchId });

  const [createTaskFromTemplate] = useCreateTaskFromTemplateMutation({
    onCompleted({ createTaskFromTemplate: { id, number, __typename } }) {
      message.success(messageContent({ id, number: number ?? 0, type: __typename ?? 'Task' }));

      // FIXME JD: Update this with more precise cache-handling once Task queries are pointed at Atlas.
      papiClient.resetStore();
      setCreatingTask(false);
      form.resetFields();
      handleClose();
    },
    onError() {
      message.error('There was a problem creating the task');
      setCreatingTask(false);
    },
    refetchQueries: [...queryRefetch, ...queriesToRefetch],
  });

  // This useEffect is responsible for setting initial assetId of the form.
  // We have to set the initial assetId this way so that when we set the asset pickers it
  // will have an asset as a form value.
  useEffect(() => {
    const initValues = { assetId: '' };
    if (initialAssetId) {
      initValues['assetId'] = initialAssetId;
    }
    if (form && visible) {
      form.setFieldsValue(initValues);
    }
  }, [form, visible, initialAssetId]);

  const getValueFromAssetChooser = (assetInfo: TAssetPickerResponse) => {
    const { assetId, isLocation, isBlade } = assetInfo;

    if (isLocation && CREATE_OTHER_SITE_TASK) {
      setSelectedAssetId(undefined);
      return undefined;
    }

    if (type === INTERNAL_BLADE_INSPECTION_TASK && !isBlade) {
      setSelectedAssetId(undefined);
      return undefined;
    }

    setSelectedAssetId(assetId);
    return assetId;
  };

  async function handleCreateTask() {
    setCreatingTask(true);

    const input = isRepairOrInspect(type)
      ? {
          id: templateId,
          damageId: parentId,
          damageBranchId,
          ...(horizonDamageId ? { horizonDamageId } : {}),
          assetId: initialAssetId,
          ...(inspectionId || horizonDamageId
            ? { targets: getTaskTargetInput({ inspectionId, horizonDamageId }) }
            : {}),
        }
      : {
          id: templateId,
          parentId: parentId,
          assetId: selectedAssetId ? selectedAssetId : initialAssetId,
        };

    await createTaskFromTemplate({
      variables: {
        input: input,
      },
    });
  }

  async function validateFields() {
    try {
      await form.validateFields();
      handleCreateTask();
    } catch (err) {
      // We want to catch the error so nothing crashes, but the form will surface any failure messages.
    }
  }

  const getErrorMessage = (): string => {
    if (type === INTERNAL_BLADE_INSPECTION_TASK) return 'Please choose a blade';
    return CREATE_OTHER_SITE_TASK ? 'Please choose a site' : 'Please choose an asset';
  };

  return (
    <Modal
      title={title}
      open={visible}
      onCancel={handleClose}
      destroyOnClose
      footer={
        <>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            type="primary"
            loading={creatingTask}
            onClick={validateFields}
            data-testid="save-btn"
          >
            Save
          </Button>
        </>
      }
    >
      <Form layout="vertical" form={form}>
        <>
          {!isRepairOrInspect(type) && (parentId || initialAssetId) && (
            <Form.Item
              name="assetId"
              getValueFromEvent={getValueFromAssetChooser}
              rules={[
                {
                  required: true,
                  message: getErrorMessage(),
                },
              ]}
            >
              {!assetId ? (
                <TaskAssetPicker
                  taskId={parentId}
                  selectedAssetId={selectedAssetId}
                  initialAssetId={initialAssetId}
                  setInitialAssetId={setInitialAssetId}
                  showLoadingIndicator={true}
                  onChange={getValueFromAssetChooser}
                />
              ) : (
                <Input disabled={true} value={assetId} />
              )}
            </Form.Item>
          )}
        </>
      </Form>
    </Modal>
  );
};
