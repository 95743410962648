import React, { useCallback, useMemo } from 'react';
import { App, message as staticMessage } from 'antd5';
import { ArgsProps, MessageType as OpenMessageResponse } from 'antd5/lib/message/interface';
import {
  InfoCircleFilled,
  CheckCircleFilled,
  CloseCircleFilled,
  ExclamationCircleFilled,
  CloseOutlined,
} from '@ant-design/icons';
import { MessageType } from './types';
import { StyledMessageDiv, StyledMessageContent } from './Messages.style';
import COLORS from 'utils/color/definitions';

type JointContent = React.ReactNode | ArgsProps;
type ConfigDuration = number | (() => void);
type ConfigOnClose = () => void;

type OpenMessage = (
  content: JointContent,
  duration?: ConfigDuration, // in seconds
  onClose?: ConfigOnClose
) => OpenMessageResponse;

interface UseMessageWithCloseArgs extends ArgsProps {
  type?: MessageType;
}

/**
 * This hook is a wrapper that allows the caller to use messages within the
 * global AntD context, but still behave somewhat similiarly to the static (and
 * now deprecated) global message functions found in AntD 4. For example:
 *
 * ```typescript
 * functon SomeComponent(props: Props) {
 *    const message = useMessage();
 *    ...
 *    message.error('👎👎')
 * }
 * ```
 *
 * By specifying a `key`, all the messages called from this context hook will
 * default to using that key. This is a convenience so you don't have to specify
 * `key` in each message. For example:
 *
 * ```typescript
 * functon AnotherComponent(props: Props) {
 *    const message = useMessage();
 *    const specialMessage = useMessage('special');
 *    ...
 *    specialMessage.loading('🤘'); // initially displays loading message
 *    ...
 *    specialMessage.success('🤘🤘🤘'); // overrides '🤘' message
 *    ...
 *    specialMessage.destroy(); // kills only the message with '🤘🤘🤘'
 * }
 * ```
 *
 * @see https://ant.design/components/message
 */
export function useMessage(key?: string) {
  const { message } = App.useApp();

  const _open = useCallback(
    (type: MessageType) => {
      const openMessage: OpenMessage = (content, duration, onClose) => {
        if (!key) {
          return message[type](content, duration, onClose);
        }

        // when the `content` is an object, we just inject the default `key` and
        // spread the remaining content options
        if (
          typeof content === 'object' &&
          Object.prototype.toString.call(content) === '[object Object]'
        ) {
          return message[type]({ key, ...content });
        }

        return message[type]({ content, duration, key, onClose });
      };
      return openMessage;
    },
    [key, message]
  );

  const withClose = useCallback(
    ({
      content,
      key: override = '',
      onClose,
      type = MessageType.INFO,
      ...args
    }: UseMessageWithCloseArgs) => {
      _open(type)({
        content: (
          <WithCloseContent
            content={content}
            messageKey={override || key}
            onClose={onClose}
            type={type}
          />
        ),
        duration: 0,
        icon: <></>,
        key: override || key,
        ...args,
      });
    },
    [_open, key]
  );

  return useMemo(
    () => ({
      // this destroy is context-aware and only works on messages created within
      // the context.
      destroy: (customKey?: React.Key) => message.destroy(key ?? customKey),
      // this destroy is a global static. calling it without any key will close
      // all messages.
      // @see https://ant.design/components/message#global-static-methods
      destroyAll: staticMessage.destroy,
      error: _open(MessageType.ERROR),
      info: _open(MessageType.INFO),
      loading: _open(MessageType.LOADING),
      message,
      open: message.open,
      success: _open(MessageType.SUCCESS),
      warning: _open(MessageType.WARNING),
      withClose,
    }),
    [_open, key, message, withClose]
  );
}

interface WithCloseContentProps {
  content: React.ReactNode;
  messageKey: React.Key | undefined;
  onClose?: () => void;
  type: MessageType;
}

function WithCloseContent({ content, messageKey, onClose, type }: WithCloseContentProps) {
  const { message } = App.useApp();

  return (
    <StyledMessageDiv>
      {type === MessageType.SUCCESS ? (
        <CheckCircleFilled />
      ) : type === MessageType.ERROR ? (
        <CloseCircleFilled />
      ) : type === MessageType.WARNING ? (
        <ExclamationCircleFilled />
      ) : (
        <InfoCircleFilled style={{ color: COLORS.TEAL }} />
      )}
      <StyledMessageContent>{content}</StyledMessageContent>
      <CloseOutlined
        onClick={() => {
          message.destroy(messageKey);
          onClose?.();
        }}
      />
    </StyledMessageDiv>
  );
}
