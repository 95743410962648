import styled from 'styled-components';
import { FlexContainer } from 'utils/layouts/containers';
import { Typography } from 'antd5';

const { Text, Title } = Typography;

export const ResetContainer = styled(FlexContainer)`
  align-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: 10vh 0 10vh 0;
`;

export const StyledText = styled(Text)`
  margin: 1rem 0;
  text-align: center;
`;

export const StyledImage = styled.img`
  height: 150px;
  padding: 0.5em 1em;
`;

export const StyledTitle = styled(Title)`
  text-align: center;

  & span {
    font-style: italic;
  }
`;
