// this is the single source of truth for color values
// these are imported into the theme for styled components
// where possible use the theme provider and avoid importing these directly into your components

const COLORS = {
  BACKGROUND_COLOR: '#f0f2f5',
  BLACK: '#111',
  BLUE: '#30aee4',
  TEAL: '#24A4AB',
  TEAL_RGBA: 'rgba(36, 164, 171, 0.2)',
  BORDER_COLOR: '#e8e8e8',
  DESCRIPTION_COLOR: 'rgba(0, 0, 0, 0.75)',

  DARK_GRAY: '#8c8c8c',
  GRAY: '#dcdfe2',
  GREEN: '#52c41a',
  LABEL_COLOR: 'rgba(0, 0, 0, 0.55)',
  LIGHT_BLUE: '#9bcce3',
  LIGHT_GRAY: '#fafafa',
  LIGHT_RED: 'rgba(245, 34, 45, 0.6)',
  LINK_COLOR: '#24A4AB',
  MAGENTA: '#ff00ff',
  OVERLAY_BLACK: 'rgba(0,0,0,0.9)',
  RED: '#f5222d',
  ORANGE: '#faad14',
  PURPLE: '#9747ff',
  SECONDARY_TEXT_COLOR: 'rgba(0, 0, 0, 0.45)',
  SEV_LESS_COLOR: '#FFA500',
  SEV_MAX_COLOR: '#FF0000',
  TEXT_COLOR: 'rgba(0, 0, 0, 0.65)',
  YELLOW: '#fadb14',
  WHITE: '#fff',
  SELECTED_LIST_ITEM_BACKGROUND: '#F0F0F0',
  HORIZON_GREEN: '#9ccb3b',
  HORIZON_GREEN_LIGHT: '#d8e6ca',
  HORIZON_GREEN_LIGHTER: '#e2ecd8',
  HORIZON_GREEN_LIGHTEST: '#eff4e9',

  GRAY_LIGHT_OPACITY: 'rgba(0, 0, 0, 0.1)',

  TRANSPARENT_WHITE: '#00000000',

  DONUT_CHART_GRAY: '#e6e6e6',
};

export default COLORS;
