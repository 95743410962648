import styled from 'styled-components';
import { QuestionCircleOutlined } from '@ant-design/icons';

export const StyledKey = styled.span`
  font-size: 1rem;
  font-weight: 700;
  color: ${({ theme }) => theme.labelColor};
`;

export const StyledQuestionCircle = styled(QuestionCircleOutlined)`
  font-size: 10px;
  align-self: center;
  grid-column-start: 2;
  grid-column-end: 2;
`;

export const TooltipGrid = styled.div`
  display: grid;
  place-items: stretch;
  grid-template-columns: auto 10px 10px;
  column-gap: 5px;
`;

export const TruncatedTooltip = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  grid-column-start: 1;
  grid-column-end: 1;
`;

export const DamageNumberText = styled.span`
  font-family: monospace;
`;
