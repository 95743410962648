import { useState, useMemo, useCallback } from 'react';
import { Button } from 'components/ui';
import {
  checkCr2,
  convertPathToJpg,
  getFileDirectoryPath,
  getFileTypesList,
  getIsFileTypeAccepted,
  handleFileRead,
} from './helpers';
import { StyledForm } from './FileUploader.style';
import { UploadContext, useUploadContext } from './UploadContext';
import {
  ConfirmProps,
  FileData,
  FilteredFiles,
  ModalAction,
  ModalActionArgs,
  ModalProps,
  ModalUploadCompleteInput,
  UploadFile,
} from './types';
import { OnCompleteStatus } from 'components/FileUploader/Modal/FileUploadModal';
import { ModalAttachmentProps } from 'components/FileUploader/types';

interface FileUploaderProviderProps {
  children: React.ReactNode;
}

export function FileUploaderProvider({ children }: FileUploaderProviderProps) {
  const [initialSelection, setInitialSelection] = useState<UploadFile[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<UploadFile[]>([]);
  const [modalProps, setModalProps] = useState<ModalProps | null>(null);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);
  const [confirmProps, setConfirmProps] = useState<ConfirmProps | null>(null);
  const [filteredFiles, setFilteredFiles] = useState<FilteredFiles>({ count: 0, status: false });
  const [corruptImgFiles, setCorruptImgFiles] = useState<string[]>([]);

  const validFiles = useMemo(() => selectedFiles.filter(({ valid }) => valid), [selectedFiles]);

  const contextValue = {
    initialSelection,
    setInitialSelection,
    selectedFiles,
    setSelectedFiles,
    validFiles,
    modalProps,
    setModalProps,
    modalVisible,
    setModalVisible,
    uploading,
    setUploading,
    confirmProps,
    setConfirmProps,
    filteredFiles,
    setFilteredFiles,
    setCorruptImgFiles,
    corruptImgFiles,
  };

  return <UploadContext.Provider value={contextValue}>{children}</UploadContext.Provider>;
}

export enum FileUploaderDisplayType {
  PrimaryButton = 'primary',
  SecondaryButton = 'secondary',
  ListItem = '',
}
interface Props {
  acceptedFileTypes?: string;
  action: ModalAction[] | ((args: ModalActionArgs[]) => Promise<ModalAction[] | undefined>);
  className?: string;
  directory?: boolean;
  disabled?: boolean;
  id?: string;
  isBulkUpload?: boolean;
  isMetadataUpload?: boolean;
  label: React.ReactNode;
  multiple: boolean;
  name?: string;
  onClose?: () => void;
  onComplete?: (args: { status: OnCompleteStatus }) => void;
  onFileComplete: (args?: ModalUploadCompleteInput) => void;
  displayType?: FileUploaderDisplayType;
  validation?: (filename: string) => boolean;
  uploadSession?: any;
  modalAttachmentProps?: ModalAttachmentProps;
}

export function FileUploader({
  acceptedFileTypes,
  action,
  className = 'fileUploader',
  directory,
  disabled = false,
  id = 'file',
  isBulkUpload = false,
  isMetadataUpload = false,
  label,
  multiple,
  name = 'files',
  onClose,
  onComplete,
  onFileComplete,
  displayType = FileUploaderDisplayType.PrimaryButton,
  validation,
  uploadSession,
  modalAttachmentProps,
}: Props) {
  const {
    initialSelection,
    setFilteredFiles,
    setInitialSelection,
    setModalProps,
    setModalVisible,
    setSelectedFiles,
    uploading,
    setCorruptImgFiles,
  } = useUploadContext();

  const handleValidation = useCallback(
    async (fileInputRef: HTMLInputElement) => {
      let corruptFiles: string[] = [];

      if (fileInputRef && fileInputRef.files) {
        const imgFiles = Array.from(fileInputRef.files).filter(
          f => f.type === 'image/jpeg' || f.type === 'image/x-canon-cr2'
        );

        try {
          // Wait for all files to be read
          corruptFiles = (
            await Promise.all(imgFiles.map(async imgFile => await handleFileRead(imgFile)))
          ).filter(Boolean) as string[];

          if (corruptFiles.length > 0) {
            // Set this value to display the number of corrupt files in the file list
            setCorruptImgFiles(corruptFiles);
          }
        } catch (error) {
          console.error('Error reading files:', error);
        }
      }

      setSelectedFiles(prevSelectedFiles => {
        return prevSelectedFiles.map(f => {
          const name = f.data?.name;
          const [cr2FileType, isCr2] = name ? checkCr2(name) : ['', false];
          const containsFilesAndDirectory = acceptedFileTypes && directory;

          // Accepted Files: always check if file type is Cr2 or in accepted types list.
          // In the case of MultipleInspection uploads this will also reset files back to `true` if they should be looked at.
          if (containsFilesAndDirectory) {
            const fileTypesArray: string[] = getFileTypesList(acceptedFileTypes);
            const fileType = f.data?.type;
            f.valid = getIsFileTypeAccepted(fileTypesArray, isCr2, cr2FileType, fileType);
          }

          // Corrupt: Check if file is in corrupt files list. Skip if we are already invalid
          if (f.valid && corruptFiles.length > 0) {
            const filename = f.name;

            f.valid = !corruptFiles.includes(filename);
          }

          // Validation: Check if file passes provided validation test. Skip if we are already invalid
          // FIXME: This validation is failing for photo + data upload in draft inspection details page - AN 9/10/24
          if (f.valid && validation) {
            f.valid = validation(isCr2 ? convertPathToJpg(f.sourceName) : f.sourceName);
          }

          // Filename: Check if filename passes provided file *name* validation test
          if (modalAttachmentProps?.validateFileName) {
            f.valid = modalAttachmentProps?.validateFileName(name);
          }
          // return object includes f.valid boolean: true if it passes the above tests
          return {
            ...f,
            key: f.sourceName,
          };
        });
      });
    },
    [
      setSelectedFiles,
      acceptedFileTypes,
      directory,
      modalAttachmentProps,
      setCorruptImgFiles,
      validation,
    ]
  );

  const handleChange = useCallback(
    async ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      const fileInputRef: HTMLInputElement = target;
      if (isBulkUpload && onClose) {
        onClose();
      }

      // The ternary here is specifically to satisfy a need with the MultipleInspectionUploadForm
      const filesArray: UploadFile[] =
        initialSelection.length > 0 && isBulkUpload
          ? initialSelection
          : Array.from(fileInputRef?.files ?? [], f => {
              const path = getFileDirectoryPath((f as FileData).webkitRelativePath ?? '');
              const name = f.name;
              const sourceName = `${path}${name}`;

              const uploadFile: UploadFile = {
                data: f as FileData,
                name,
                path,
                percent: 0,
                sourceName,
                type: directory ? 'directory' : 'file',
                valid: true,
              };
              return uploadFile;
            });

      // We only want to set an initialSelection if the initialSelection is empty and we're on
      // the metadataUpload step of MultipleInspectionUploadForm. Otherwise we'll have a weird
      // state when using the fileuploader in other areas.
      if (initialSelection.length === 0 && isMetadataUpload) {
        setInitialSelection(filesArray);
      }
      // Filter out all .DS_Store files from the selected files. ch14418
      const filteredFiles = filesArray.filter(file => file.data?.name !== '.DS_Store');

      setSelectedFiles(filteredFiles);
      setFilteredFiles({
        count: filesArray.length - filteredFiles.length,
        status: filesArray.length !== filteredFiles.length,
      });
      await handleValidation(fileInputRef);
      setModalProps({
        action,
        fileInputRef: { current: fileInputRef },
        isBulkUpload,
        isMetadataUpload,
        acceptedFileTypes,
        onClose,
        onComplete,
        onFileComplete,
        uploadSession,
        modalAttachmentProps,
      });

      setModalVisible(true);
    },
    [
      action,
      directory,
      modalAttachmentProps,
      acceptedFileTypes,
      handleValidation,
      initialSelection,
      isBulkUpload,
      isMetadataUpload,
      onClose,
      onComplete,
      onFileComplete,
      setFilteredFiles,
      setInitialSelection,
      setModalProps,
      setModalVisible,
      setSelectedFiles,
      uploadSession,
    ]
  );

  const inputProps = directory
    ? { webkitdirectory: 'true', directory: 'true' }
    : { accept: acceptedFileTypes };

  return (
    <StyledForm>
      {isBulkUpload ? (
        <Button
          className={`ant-btn ant-btn-primary`}
          onClick={handleChange}
          disabled={uploading || disabled}
        >
          Upload
        </Button>
      ) : (
        <>
          <label
            className={displayType && `ant-btn ant-btn-${displayType} ${className}`}
            htmlFor={id}
          >
            {label}
          </label>
          <input
            disabled={uploading || disabled}
            id={id}
            multiple={multiple}
            name={name}
            onChange={handleChange}
            type="file"
            {...inputProps}
          />
        </>
      )}
    </StyledForm>
  );
}
