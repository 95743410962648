import { Children, cloneElement, isValidElement, ReactElement, useCallback } from 'react';
import { AtlasGqlAction } from 'types/atlas-graphql';
import { useAccountContext } from 'utils/account/AccountContext';

export interface RenderWithAccessProps {
  // `false` is allowed for convenience purposes where a boolean is checked to
  // render the children or not. for example:
  //    <RenderWithAccess>{canShow && <SomeComonent />}</RenderWithAccess>
  children: ReactElement | ReactElement[] | null | false;
  fallbackRender?: () => ReactElement | null;
  rules?: (AtlasGqlAction | string | boolean)[];
}

export function RenderWithAccess({
  children,
  fallbackRender,
  rules,
  // 🐉 here be dragons 🐉
  // additional props are not explicitly defined on this component, but it is
  // expected that antd will pass additional props to its Form.Item children
  // (and probably other components) because that's how it rolls.
  ...props
}: RenderWithAccessProps) {
  const { canAccess } = usePermissions();

  if (rules?.length && !canAccess(rules)) {
    return fallbackRender ? fallbackRender() : null;
  }

  // in cases where additional props are provided, intentionally loop through
  // all children and pass those props along. thanks, antd. and just in case you
  // get the genius idea of removing this and just rendering/returning the
  // children, you'll be in for some really fun debugging. god be with you.
  if (props && Object.keys(props).length > 0) {
    return Children.toArray(children)
      .filter((child): child is ReactElement => isValidElement(child))
      .map(child => cloneElement(child, props)) as unknown as ReactElement;
  }

  // `false` will render as `null`
  return <>{children || null}</>;
}

export function usePermissions() {
  const { customer, user } = useAccountContext();
  const customerId = customer?.id;

  const getCurrentRole = useCallback(() => {
    return user?.roles?.find(role => role.organizationId === customerId);
  }, [customerId, user]);

  const hasRole = useCallback(
    (role?: string) => {
      if (!role) return false;
      return getCurrentRole()?.name === role;
    },
    [getCurrentRole]
  );

  const canAccess = useCallback(
    // array of rules provided treated as ORs - meaning only 1 rule is needed to
    // grant access
    (rules?: (AtlasGqlAction | string | boolean)[]) => {
      if (!rules || rules.length === 0) return false;

      if (rules.every(rule => rule === true)) {
        // backwards-compatibility support when all rules are `true`
        return true;
      }

      const role = getCurrentRole();

      if (!role || !role.actions || role.actions.length === 0) {
        return false;
      }

      const actionSet = new Set(Object.values(role.actions) as string[]);

      return rules.some(rule => actionSet.has(String(rule)));
    },
    [getCurrentRole]
  );

  return { canAccess, hasRole };
}
