import { useState } from 'react';
import { ConfirmationModal } from 'components/ConfirmationModal';
import {
  AtlasGqlDamageInspectTask,
  AtlasGqlDamageRepairTask,
  AtlasGqlTaskTargetType,
  useGetTaskTargetsQuery,
  useUpdateTaskTargetsMutation,
} from 'types/atlas-graphql';
import { getPrimaryObservationGroupForDamage } from '../utils';
import { useMessage } from 'components/ui';

type UpdateToPrimaryModalProps = {
  taskIds: string[];
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  onError?: () => void;
};

export const UpdateToPrimaryModal: React.FunctionComponent<UpdateToPrimaryModalProps> = ({
  taskIds,
  isOpen,
  onClose,
  onSuccess,
}) => {
  const message = useMessage();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [inputs, setInputs] = useState<{ taskId: string; inspectionId: string }[]>([]);

  // query for tasks (to get fresh data)
  const { loading: tasksLoading } = useGetTaskTargetsQuery({
    variables: { filterBy: { key: 'id', values: taskIds }, limit: taskIds.length },
    fetchPolicy: 'network-only',
    skip: !isOpen || taskIds.length === 0,
    onCompleted: data => {
      const tasks = data?.tasks?.items;
      if (tasks) {
        const _inputs = tasks
          .filter((task): task is AtlasGqlDamageInspectTask | AtlasGqlDamageRepairTask => !!task)
          .map(task => {
            const taskId = task.id;
            const inspectionId = getPrimaryObservationGroupForDamage(
              task.horizonDamage
            )?.inspectionId;

            return { taskId, inspectionId };
          })
          .filter(input => !!input.taskId && !!input.inspectionId);

        setInputs(_inputs);
      }
    },
  });

  // update task mutation
  const [updateTask] = useUpdateTaskTargetsMutation();

  // bulk submit the update task mutation
  const handleSubmit = async () => {
    setIsLoading(true);
    await Promise.all(
      inputs.map(input => {
        const { taskId, inspectionId } = input;
        return updateTask({
          variables: {
            id: taskId,
            input: {
              targets: [
                {
                  targetId: inspectionId,
                  targetType: AtlasGqlTaskTargetType.Inspection,
                },
              ],
            },
          },
        });
      })
    );
    setIsLoading(false);

    message.success(
      inputs.length > 1
        ? `${inputs.length} Tasks were successfully updated.`
        : 'Task was successfully updated.'
    );

    onSuccess && onSuccess();
    onClose();
  };

  return (
    <ConfirmationModal
      open={isOpen}
      loading={tasksLoading || isLoading}
      title="Confirm Update"
      okText="Update"
      onOk={handleSubmit}
      cancelText="Cancel"
      onCancel={onClose}
      message={
        inputs.length > 1
          ? `This action will update the Task Targets for these ${inputs.length} Tasks to the Damage's Primary Observation Group. ` +
            `This will affect the Damage attributes and photos associated with these ${inputs.length} Tasks. ` +
            'Do you wish to continue?'
          : "This action will update the Task Target for this Task to the Damage's Primary Observation Group. " +
            'This will affect the Damage attributes and photos associated with this Task. ' +
            'Do you wish to continue?'
      }
      destroyOnClose
    />
  );
};
