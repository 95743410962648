import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { PageHeaderLayout } from 'components/PageHeaderLayout';
import { useApolloContext } from 'utils/apollo';
import { useAccountContext } from 'utils/account/AccountContext';
import { FULL_TURBINE_IMAGE } from 'utils/constants';
import { Spinner } from 'components/ui';
import { ResetContainer, StyledImage, StyledTitle } from './Reset.style';

// this reset component will clear the apollo cache before redirecting onto the
// new page in the context of a new customer. this component is typically only
// visited when the user is changing customers or they are attempting to access
// a customer they don't have access to. it's important to clear the apollo
// cache so that the user doesn't get data from Customer A while viewing
// Customer B.
function Reset() {
  const { customer } = useAccountContext();
  const { resetCache } = useApolloContext();
  const { search, state } = useLocation<{ redirectUrl?: string }>();

  let redirectUrl = state?.redirectUrl;

  if (!redirectUrl && search) {
    const parsed = queryString.parse(search);
    redirectUrl = parsed.redirectUrl as string | undefined;
  }

  if (!redirectUrl) redirectUrl = '/';

  useEffect(() => {
    (async () => {
      await resetCache();

      // add a slight delay so the reset is less jarring, and use
      // window.location to get a full app reload
      setTimeout(() => {
        window.location.replace(String(redirectUrl));
      }, 750);
    })();
    // we only want this to run once on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageHeaderLayout>
      <ResetContainer data-testid="reset-container">
        <StyledImage
          alt="turbine icon"
          // hide images from LogRocket
          data-private="logrocket-hide"
          src={FULL_TURBINE_IMAGE}
        />
        <StyledTitle level={3}>
          Just a moment while we configure <span>{customer?.name ?? 'this customer'}</span>{' '}
          <Spinner />
        </StyledTitle>
      </ResetContainer>
    </PageHeaderLayout>
  );
}

export default Reset;
