import { useMemo } from 'react';
import { isEmpty, orderBy } from 'lodash';
import { useHistory } from 'utils/router';

import { Tooltip } from 'components/ui';
import { None } from 'components/data/helpers';
import { AtlasGqlHorizonDamage } from 'types/atlas-graphql';
import { TTableColumnDef } from 'horizon/types/TableColumnDef';
import {
  formatDamageId,
  getPrimaryObservationForDamage,
  getPrimaryObservationGroupForDamage,
} from 'horizon/components/Damages/utils';
import { CriticalLevel } from 'components/SeverityAndCriticalIndicator';

const renderAttrs = (
  attrs: { [key: string]: any },
  damageColumns: any[],
  critical?: boolean | null
) => {
  const attrsArray = attrs ? Object.keys(attrs) : [];
  return (
    <>
      {damageColumns
        .filter(
          column => attrsArray.includes(column.key) || (column.key === 'Severity' && critical)
        )
        .map(column => {
          const key = column.key;
          const value = column.render(
            attrs[key],
            column.key === 'Severity' && critical
              ? { critical, criticalLevel: CriticalLevel.Observation }
              : {}
          );

          return (
            <div key={key + value}>
              <b>{key}:</b> {value}
            </div>
          );
        })}
    </>
  );
};

const renderAdditionalAttrs = (key: string, value: string = '') => (
  <div key={key + value}>
    <b>{key}:</b> {!isEmpty(value) ? value : <None />}
  </div>
);

export type PlotTooltipProps = {
  damages?: AtlasGqlHorizonDamage[];
  position?: { x: number; y: number };
  damageColumns: TTableColumnDef[];
  onChange: (open: boolean) => void;
};

export const PlotTooltip: React.FunctionComponent<PlotTooltipProps> = ({
  damages,
  position,
  damageColumns,
  onChange,
}: PlotTooltipProps) => {
  const history = useHistory();

  // get damage information
  const { primaryDamage, primaryObservation, primaryObservationGroup, otherDamages } =
    useMemo(() => {
      // sort damages by Severity
      const sortedDamages = orderBy(
        damages,
        ({ primaryObservationGroupAttrs }) => primaryObservationGroupAttrs.Severity ?? 0,
        'desc'
      );

      const primaryDamage = sortedDamages.length > 0 ? sortedDamages[0] : undefined;
      return {
        primaryDamage,
        primaryObservation: getPrimaryObservationForDamage(primaryDamage),
        primaryObservationGroup: getPrimaryObservationGroupForDamage(primaryDamage),
        otherDamages: sortedDamages.filter(d => d.id !== primaryDamage?.id),
      };
    }, [damages]);

  // opens the image viewer with the primary observation for the damage
  const handleClick = () => {
    const pictureId = primaryObservation?.picture?.id;
    const observationId = primaryObservation?.id;

    if (pictureId && observationId) {
      history.push(`/pictures/${pictureId}?observation=${observationId}`, {
        useModal: true,
      });
    }
  };

  const shouldRender = primaryDamage && primaryObservation && primaryObservationGroup && position;

  return shouldRender ? (
    <Tooltip
      title={
        <div style={{ cursor: 'pointer' }} role="button" onClick={handleClick}>
          {renderAdditionalAttrs('Damage ID', formatDamageId({ id: primaryDamage.id }))}
          {renderAttrs(
            primaryObservationGroup.groupAttrs ?? {},
            damageColumns,
            primaryObservationGroup.groupCritical ?? false
          )}
          {otherDamages.length > 0 && (
            <div style={{ marginTop: '10px' }}>
              <i>{otherDamages.length} other damages at this point</i>
            </div>
          )}
        </div>
      }
      onOpenChange={onChange}
    >
      <circle
        cx={position?.x}
        cy={position?.y}
        r="5"
        fill="black"
        fillOpacity="0"
        style={{ cursor: 'pointer' }}
        onClick={handleClick}
      />
    </Tooltip>
  ) : null;
};
