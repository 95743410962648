import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';
import { Popconfirm } from 'antd';
import { RenderWithAccess } from 'utils/usePermissions';
import { DAMAGE_EDIT_ATTRIBUTES } from 'utils/access-control/rules';

import EditableDamageAttributes from 'components/damages/EditableDamageAttributes';
import { Button, Spin, Spinner } from 'components/ui';
import { renderColumn } from 'components/data/helpers';
import { useAllDamageSchemasQuery } from 'types/atlas-graphql';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`;

const LabelColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 7rem;
`;

const AttributeColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: hidden;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.div`
  height: ${({ edit }) => (edit ? '2.25rem' : '1.75rem')};
`;

const AttributeValue = styled.div`
  height: ${({ edit }) => (edit ? '2.25rem' : '1.75rem')};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const StyledDeleteButtonLink = styled(Button)`
  color: ${({ theme }) => theme.lightRed} !important;
  &:hover {
    color: ${({ theme }) => theme.red} !important;
  }
  padding: 0 !important;
`;

export const MODES = {
  VIEW: 'VIEW',
  EDIT: 'EDIT',
};

export function DamageAttributes({
  loading,
  damage,
  schemaId,
  potentialAttrs,
  damageColumns = [],
  mode = MODES.VIEW,
  currentMode = MODES.VIEW,
  onSave,
  saving,
  onReset,
  onCancel,
  ...props
}) {
  const { data } = useAllDamageSchemasQuery();
  const damageSchemas = data?.damageSchemas ?? [];
  const schemaName = damageSchemas.find(({ id }) => id === schemaId)?.name;
  const shouldShowSchema = !!(damageSchemas?.length > 1 && schemaName);

  const filteredColumns = damageColumns.filter(col => {
    return Object.keys(damage?.attrs || {}).includes(col.key);
  });

  return (
    <Container {...props}>
      {loading ? (
        <Spinner />
      ) : (
        <ContentContainer>
          {mode === MODES.VIEW && currentMode === MODES.VIEW && (
            <Row>
              <LabelColumn>
                {shouldShowSchema && <Label key="label_schema">Schema</Label>}
                {filteredColumns.map((c, i) => (
                  <Label key={`label_${i}`}>{c.title}</Label>
                ))}
              </LabelColumn>
              <AttributeColumn>
                {shouldShowSchema && (
                  <AttributeValue key="value_schema">{schemaName}</AttributeValue>
                )}
                {filteredColumns.map((c, i) => (
                  <AttributeValue key={`value_${i}`}>{renderColumn(c, damage)}</AttributeValue>
                ))}
              </AttributeColumn>
            </Row>
          )}
          {mode === MODES.EDIT && currentMode === MODES.EDIT && (
            <Row style={{ width: '100%' }}>
              <EditableDamageAttributes
                formLayout="horizontal"
                damage={damage}
                schemaId={schemaId}
                potentialAttrs={potentialAttrs}
                shouldHideRealtimePotentialAttrs
                shapeDrawn={true}
                onCancel={onCancel}
                onSave={onSave}
                showComment={false}
              />
            </Row>
          )}
          <RenderWithAccess rules={[DAMAGE_EDIT_ATTRIBUTES]}>
            {mode === MODES.VIEW && onReset && (
              <Row style={{ justifyContent: 'flex-end' }}>
                {saving ? (
                  <Spin _version={4} indicator={<LoadingOutlined />} />
                ) : (
                  <Popconfirm
                    title="This resets all the attributes for this damage back to their original values. Are you sure?"
                    onConfirm={onReset}
                    okButtonProps={{ id: 'reset-damage-confirm-btn' }}
                  >
                    <StyledDeleteButtonLink _version={4} type="link">
                      Reset
                    </StyledDeleteButtonLink>
                  </Popconfirm>
                )}
              </Row>
            )}
          </RenderWithAccess>
        </ContentContainer>
      )}
    </Container>
  );
}
