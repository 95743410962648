import { useMemo } from 'react';

import { isEmpty } from 'lodash';
import { BulkEdit } from 'utils/editable';

import { SpacedContainer } from 'utils/layouts/containers';
import { DAMAGE_EDIT_ATTRIBUTES } from 'utils/access-control/rules';
import { RenderWithAccess } from 'utils/usePermissions';
import { ExpandedDamage } from 'components/damages/ExpandedDamage/ExpandedDamage';
import DataTable from 'components/DataTable';
import { limitFiltersFromData } from 'components/data/helpers';
import { useDamageAttrs } from 'components/damages/withDamages';
import { useMutation } from 'utils/apollo';
import ADD_USER_DAMAGE from 'components/damages/DamageEdit/AddUserDamage.gql';
import { EFilterSortClearType } from 'components/DataTable/types';

const CONFIRMED_TAB_LIST = [
  {
    key: 'ALL',
    tab: 'All',
    value: {
      filters: { isConfirmed: undefined },
      sortOrder: { columnKey: 'Severity', order: 'descend' },
    },
  },
  {
    key: 'CONFIRMED',
    tab: 'Confirmed',
    value: { filters: { isConfirmed: ['true'] } },
  },
  {
    key: 'UNCONFIRMED',
    tab: 'Unconfirmed',
    value: { filters: { isConfirmed: ['false'] } },
  },
  {
    key: 'SEVERE',
    tab: undefined,
    value: {
      filters: { Severity: [[4]] },
      sortOrder: { columnKey: 'Severity', order: 'descend' },
    },
  },
  {
    key: 'DATE_DESC',
    tab: undefined,
    value: {
      sortOrder: { columnKey: 'Date', order: 'descend' },
    },
  },
];

const expandedRowRender = damage => <ExpandedDamage damageId={damage.id} />;

export function DamageTable({
  columns = [],
  data = [],
  dataQuery,
  loading = false,
  actions,
  defaultActiveTabKey = 'ALL',
  schema,
  selected = [],
  onClearSelection = () => {},
  additionalDropdownActions = [],
  ...props
}) {
  const { damageColumns, loading: loadingAttrs } = useDamageAttrs(schema);

  const allColumns = useMemo(
    () =>
      [
        ...columns,
        ...damageColumns.map(col => (dataQuery ? col : limitFiltersFromData(col, data))),
      ].map(c => ({
        ...c,
      })),
    [columns, damageColumns, dataQuery, data]
  );

  const [addUserDamage] = useMutation(ADD_USER_DAMAGE);

  const handleBulkEditSubmit = async values => {
    await Promise.all(
      selected.map(damage => {
        const mergedAttrs = { ...damage.attrs, ...values };
        const attrs = Object.keys(mergedAttrs)
          .map(key => {
            const value = mergedAttrs[key];

            if (typeof value === 'object' && 'toDate' in value) {
              return { name: key, date: value.format('YYYY-MM-DD') };
            }
            if (typeof value === 'number') {
              return { name: key, number: value };
            }

            return { name: key, string: value };
          })
          .filter(
            attr =>
              (attr?.date && !isEmpty(attr.date)) ||
              (attr?.number && isFinite(attr.number)) ||
              (attr?.string && !isEmpty(attr.string))
          );

        const { id, schema, observations } = damage;
        const schemaId = schema?.id;
        const { id: observationId, coordinates, picture } = observations[0];
        const pictureId = picture?.id;

        return addUserDamage({
          variables: {
            damage: {
              branchGlobalId: id,
              schemaId,
              attrs,
              annotations: [
                {
                  branchGlobalId: observationId,
                  coordinates,
                  pictureId,
                },
              ],
            },
          },
        });
      })
    );

    onClearSelection();
  };

  return (
    <DataTable
      loading={loading || loadingAttrs}
      columns={allColumns}
      expandedRowRender={expandedRowRender}
      data={data}
      dataQuery={dataQuery}
      additionalDropdownActions={additionalDropdownActions}
      actions={
        <>
          {actions}
          {!!selected.length && (
            <SpacedContainer>
              <RenderWithAccess rules={[DAMAGE_EDIT_ATTRIBUTES]}>
                <BulkEdit
                  records={selected.map(({ id, attrs }) => ({ id, ...attrs }))}
                  columns={damageColumns}
                  onSubmit={handleBulkEditSubmit}
                />
              </RenderWithAccess>
            </SpacedContainer>
          )}
        </>
      }
      emptyText="You currently have no damages."
      tabList={CONFIRMED_TAB_LIST.filter(({ tab }) => tab)}
      initialPresetFilter={CONFIRMED_TAB_LIST.find(tab => tab.key === defaultActiveTabKey)}
      clearSortAndFilterType={EFilterSortClearType.RESET}
      {...props}
    />
  );
}
