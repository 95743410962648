import { Button, Tooltip } from 'components/ui';
import { useRelatedPictures } from 'components/ImageViewer/useRelatedPictures';
import { sortBy } from 'lodash';
import React from 'react';
import {
  AtlasGqlBlade,
  AtlasGqlGenericAsset,
  AtlasGqlInspection,
  AtlasGqlPicture,
} from 'types/atlas-graphql';
import { ModalLink } from 'utils/router';
import { usePermissions } from 'utils/usePermissions';
import { WORK_ORDER_COLLABORATOR } from 'utils/access-control/roles';

type CompareMostRecentImageProps = {
  picture: AtlasGqlPicture;
  observationId: string;
};

const getPictureAssetId = ({ target }: AtlasGqlPicture): string | undefined => {
  if ('bladeAsset' in target) {
    return (target.bladeAsset as AtlasGqlBlade).id;
  }
  if ('tower' in target) {
    return (target.tower as AtlasGqlGenericAsset).id;
  }
  if ('transitionPiece' in target) {
    return (target.transitionPiece as AtlasGqlGenericAsset).id;
  }
  if ('nacelle' in target) {
    return (target.nacelle as AtlasGqlGenericAsset).id;
  }
  if ('gearbox' in target) {
    return (target.gearbox as AtlasGqlGenericAsset).id;
  }
  if ('asset' in target) {
    return (target.asset as AtlasGqlGenericAsset).id;
  }
};

export const CompareMostRecentImage: React.FunctionComponent<CompareMostRecentImageProps> = ({
  picture,
  observationId,
}) => {
  const { inspections = [] } = useRelatedPictures({ picture });

  const { hasRole } = usePermissions();
  if (hasRole(WORK_ORDER_COLLABORATOR)) {
    return null;
  }

  const mostRecentInspection: AtlasGqlInspection | undefined = sortBy(
    inspections,
    'inspectionDate'
  ).reverse()[0];

  if (
    mostRecentInspection?.inspectionDate &&
    picture.inspection?.inspectionDate &&
    mostRecentInspection.inspectionDate > picture.inspection.inspectionDate
  ) {
    const mostRecentPictureId = mostRecentInspection.pictures?.find(
      p => p && getPictureAssetId(p) === getPictureAssetId(picture)
    )?.id;

    return (
      mostRecentPictureId && (
        <Tooltip title="New inspection data is available for this damage. Click to view.">
          <ModalLink
            to={`/pictures/${picture.id}?observation=${observationId}&picture2=${mostRecentPictureId}`}
          >
            <Button type="primary">View Most Recent</Button>
          </ModalLink>
        </Tooltip>
      )
    );
  }

  return null;
};
