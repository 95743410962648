import styled from 'styled-components';
import { get } from 'lodash';
import { Skeleton } from 'antd';
import { useHistory } from 'react-router-dom';

import { RenderWithAccess } from 'utils/usePermissions';
import { DAMAGE_DELETE, TASK_CREATE } from 'utils/access-control/rules';
import { ASSET_TYPE_TURBINE } from 'utils/constants';

import { ImageViewer } from 'components/ImageViewer';
import { FlexContainer } from 'utils/layouts/containers';
import { CreateTask } from 'components/CreateTask/CreateTask';
import { ConfirmDamageToggle } from 'components/ConfirmDamageToggle';
import { Spinner } from 'components/ui';
import { DeleteDamage } from 'components/DeleteDamage';
import { Comments } from 'components/comments';
import { DamageAttributesCard } from 'components/damages/DamageAttributes/DamageAttributesCard';
import { AssetDetailCard } from 'horizon/routes/WorkOrders/Tasks/TaskDetail/AssetDetailCard';
import { usePotentialDamageAttrs } from 'utils/usePotentialDamageAttrs';

import { DamageDetailsLink } from '../DamageDetailsLink';
import { AtlasGqlAsset, useGetDamageQuery } from 'types/atlas-graphql';
import { extractAssetIdFromDamageSchema } from '../utils';
import { getAssetByType } from 'horizon/components/Assets/AssetDetails/helpers';

const DamageDetails = styled.div`
  flex: 2;
`;

const DamageDetail = styled(FlexContainer)`
  align-items: start;
  .ant-skeleton-element,
  .ant-skeleton-image {
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
    background-size: 400% 100%;
    animation: ant-skeleton-loading 1.4s ease infinite;
  }
`;

const ImageContainer = styled.div`
  width: 500px;
  height: 350px;
  position: relative;
  margin-right: 2em;
`;

const DamageAttrsContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 20rem;
  flex: 1 1 auto;
`;

const DamageDataContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 11rem;
  button {
    margin-bottom: 0.5rem;
  }
`;

const StyledAttributesCard = styled(DamageAttributesCard)`
  height: auto !important;
`;

const StyledComments = styled(Comments)`
  flex: 1;
  max-width: 50vw;
  margin-left: 2rem;
`;

const DamageAssetContainer = styled.div`
  margin: 0 1rem;
`;

const SpinnerContainer = styled.div`
  height: 100%;
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type IExpandedDamageProps = {
  damageId: string;
  hideDetails: boolean;
};

export const ExpandedDamage: React.FunctionComponent<IExpandedDamageProps> = ({
  damageId,
  hideDetails,
}) => {
  const history = useHistory();
  const { data, loading } = useGetDamageQuery({
    variables: { id: damageId },
  });

  const damage = data?.damage;
  const schemaId = damage?.schema?.id;
  const damageBranchId = damage?.branchGlobalId;
  const assetId = damage?.assetId ?? extractAssetIdFromDamageSchema(damage);
  const observations = damage?.observations ?? [];

  const { coordinates, picture } = get(observations, '[0]', {});
  const pictureId = picture?.id;
  const turbine = getAssetByType(damage?.asset as AtlasGqlAsset, 'Turbine');

  const { potentialAttrs, loading: potentialAttrsLoading } = usePotentialDamageAttrs({
    pictureId,
    coordinates,
  });

  const handleSelectDamage = () => {
    history.push(`/pictures/${picture.id}?damages=${damageId}`, { useModal: true });
  };

  if (!damage || potentialAttrsLoading) {
    return (
      <SpinnerContainer>
        <Spinner size={3} />
      </SpinnerContainer>
    );
  }

  return (
    <DamageDetails>
      <DamageDetail>
        <ImageContainer>
          {loading ? (
            <Skeleton.Image />
          ) : (
            <ImageViewer
              {...picture}
              selectDamage={handleSelectDamage}
              // setting observations to 'selected' will cause automatic zoom
              observations={observations.map(o => ({
                ...o,
                damageId,
                selected: true,
              }))}
            />
          )}
        </ImageContainer>

        <DamageDataContainer>
          <DamageAttrsContainer>
            <StyledAttributesCard
              title="Damage Details"
              damage={damage}
              schemaId={schemaId}
              potentialAttrs={potentialAttrs}
              hideDetails={hideDetails}
            />
            {!hideDetails && (
              <ButtonContainer>
                <ConfirmDamageToggle damage={damage} type={null} />
                <RenderWithAccess rules={[TASK_CREATE]}>
                  <CreateTask
                    damageId={damage?.globalId || damageId}
                    damageBranchId={damageBranchId}
                    assetId={assetId}
                    useMenuModal={true}
                  />
                </RenderWithAccess>
                <DamageDetailsLink id={damage?.id} type="button" />
                <RenderWithAccess rules={[DAMAGE_DELETE]}>
                  <DeleteDamage damage={damage} forceIdCheck={false} type="button" />
                </RenderWithAccess>
              </ButtonContainer>
            )}
          </DamageAttrsContainer>
          {!hideDetails && (
            <>
              <DamageAssetContainer>
                <AssetDetailCard assetType={ASSET_TYPE_TURBINE} assetData={turbine} />
              </DamageAssetContainer>

              <StyledComments threadId={damage?.id} />
            </>
          )}
        </DamageDataContainer>
      </DamageDetail>
    </DamageDetails>
  );
};
