import { Checkbox } from 'antd5';
import styled from 'styled-components';
import COLORS from 'utils/color/definitions';

export const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox {
    margin-top: -4px;
  }
`;

export const PlotImgContainer = styled.div`
  border: 1px solid ${COLORS.GRAY};
  border-radius: 3px;

  width: 280px;
    padding: 4px 6px 2px 4px;
    margin: 20px 0 10px 0;

  img {
    object-fit: contain;
    width 100%;
  }
`;
