import { Card } from 'antd';

import {
  ASSET_TYPE_LOCATION,
  ASSET_TYPE_SITE,
  ASSET_TYPE_TURBINE,
  ASSET_TYPE_BLADE,
  ASSET_TYPE_GENERIC,
} from 'utils/constants';
import { usePermissions } from 'utils/usePermissions';
import { WORK_ORDER_COLLABORATOR } from 'utils/access-control/roles';

import { DataDescription } from 'components/DataDescription';
import {
  name as turbineName,
  location,
  status,
  make,
  model,
  atlasLocation,
} from 'components/data/turbines';
import {
  label,
  serial,
  manufacturer,
  make as bladeMake,
  atlasPosition,
} from 'components/data/blades';
import {
  make as genericMake,
  serial as genericSerial,
  model as genericModel,
  position as genericPosition,
} from 'components/data/generic';
import { name as locationName } from 'components/data/locations';
import { removeAssetLink } from './helpers';
import { getAssetPosition } from 'utils/asset-helpers';

interface IAssetDetailCardProps {
  assetData: any;
  assetType: string;
  // A temporary flag to account for this component optionally taking in data from Atlas or PAPI
  useAtlasData?: boolean;
}

export const AssetDetailCard: React.FunctionComponent<IAssetDetailCardProps> = ({
  assetData,
  assetType,
  useAtlasData = false,
}) => {
  const { hasRole } = usePermissions();
  const isWoCollaborator = hasRole(WORK_ORDER_COLLABORATOR);

  /* work order collaborators need to have the asset links scrubbed off so they don't click into
     asset details pages that they shouldn't have access to
  */
  const cleanLocationName = isWoCollaborator ? removeAssetLink(locationName) : locationName;
  const locationColumns = [{ ...cleanLocationName, title: 'Site' }];
  const turbineLocationColumn = useAtlasData ? atlasLocation : location;
  const turbineColumns = [
    isWoCollaborator ? removeAssetLink(turbineName) : turbineName,
    isWoCollaborator ? removeAssetLink(turbineLocationColumn) : turbineLocationColumn,
    status,
    make,
    model,
  ];
  const bladeColumns = [
    useAtlasData ? atlasPosition : label,
    isWoCollaborator ? removeAssetLink(serial) : serial,
    useAtlasData ? bladeMake : manufacturer,
  ];

  const genericColumns = [
    isWoCollaborator ? removeAssetLink(genericSerial) : genericSerial,
    genericMake,
    genericModel,
  ];

  const position = getAssetPosition(assetData);
  if (position) {
    genericColumns.unshift(genericPosition);
  }

  const attrMap = {
    [ASSET_TYPE_LOCATION]: locationColumns,
    [ASSET_TYPE_SITE]: locationColumns,
    [ASSET_TYPE_TURBINE]: turbineColumns,
    [ASSET_TYPE_BLADE]: bladeColumns,
    [ASSET_TYPE_GENERIC]: genericColumns,
  };

  const attributes = attrMap[assetType as keyof typeof attrMap];

  const assetTypeName =
    assetType === ASSET_TYPE_GENERIC ? assetData?.assetType?.name ?? 'Component' : assetType;

  return (
    <Card title={`${assetTypeName} Details`}>
      <DataDescription attributes={attributes} data={assetData} loading={false} />
    </Card>
  );
};
