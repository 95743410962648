import { useRef, useState } from 'react';
import { Button } from 'components/ui';
import { UnorderedListOutlined } from '@ant-design/icons';
import { orderBy } from 'lodash';
import { ColorCodingMap, NullShapeAndColor } from '../pointUtils';
import { DamageCountSpan, LegendPopover } from './ColorCodingLegend.style';
import { ColorCodingAttribute } from '../BladeDamagePlotContainer';
import { useIsInView } from 'utils/hooks';
import { DEFAULT_OPACITY } from '../pointShapes';
import { propagationTypeMapping } from '../../utils';

type ColorCodingLegendProps = {
  colorCodingAttribute: ColorCodingAttribute;
  colorCodingMap: ColorCodingMap;
  totalDamageCount: number;
};

export const ColorCodingLegend: React.FunctionComponent<ColorCodingLegendProps> = ({
  colorCodingAttribute,
  colorCodingMap,
  totalDamageCount,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const topRef = useRef<HTMLDivElement>(null);
  const bottomRef = useRef<HTMLDivElement>(null);
  const isScrolledToTop: boolean = useIsInView(topRef);
  const isScrolledToBottom: boolean = useIsInView(bottomRef);

  const noValueDamageCount: number =
    totalDamageCount -
    Object.values(colorCodingMap).reduce((acc, { damageCount }) => acc + (damageCount ?? 0), 0);

  return (
    <LegendPopover
      open={isOpen}
      placement="topRight"
      getPopupContainer={triggerNode => triggerNode}
      $isScrolledToTop={isScrolledToTop}
      $isScrolledToBottom={isScrolledToBottom}
      content={
        <div>
          <div ref={topRef} />
          <ul>
            {colorCodingMap &&
              orderBy(
                Object.entries(colorCodingMap),
                [0],
                [
                  [ColorCodingAttribute.Severity, ColorCodingAttribute.OpSeverity].includes(
                    colorCodingAttribute
                  )
                    ? 'desc'
                    : 'asc',
                ]
              ).map(
                (
                  [
                    value,
                    {
                      symbol: { ShapeComponent, color },
                      damageCount,
                    },
                  ],
                  i
                ) =>
                  !!damageCount && (
                    <li key={i}>
                      <svg
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        transform="scale(2)"
                      >
                        <ShapeComponent x={5} y={5} color={color} opacity={DEFAULT_OPACITY} />
                      </svg>
                      <span>
                        {[ColorCodingAttribute.Severity, ColorCodingAttribute.OpSeverity].includes(
                          colorCodingAttribute
                        )
                          ? colorCodingAttribute
                          : ''}{' '}
                        {colorCodingAttribute === ColorCodingAttribute.PropagationType
                          ? propagationTypeMapping[value]
                          : value}
                      </span>
                      : <DamageCountSpan>{damageCount}</DamageCountSpan>
                    </li>
                  )
              )}
            <li>
              <svg
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                transform="scale(2)"
              >
                <NullShapeAndColor.ShapeComponent
                  x={5}
                  y={5}
                  color={NullShapeAndColor.color}
                  opacity={DEFAULT_OPACITY}
                />
              </svg>
              <span> No {colorCodingAttribute}</span>:{' '}
              <DamageCountSpan>{noValueDamageCount}</DamageCountSpan>
            </li>
          </ul>
          <div ref={bottomRef} />
        </div>
      }
      arrow={false}
    >
      <Button icon={<UnorderedListOutlined />} onClick={() => setIsOpen(!isOpen)} />
    </LegendPopover>
  );
};
