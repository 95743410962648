import { useEffect, useMemo, useCallback, useRef, useState } from 'react';
import * as d3 from 'd3';
import { clamp } from 'lodash';

import { AtlasGqlHorizonDamage, AtlasGqlHorizonDamagePropagation } from 'types/atlas-graphql';
import { TTableColumnDef } from 'horizon/types/TableColumnDef';
import { BladeSide, ColorCodingAttribute } from './BladeDamagePlotContainer';
import { Axes } from './Axes';
import { EdgeShape } from './EdgeShape';
import { SideShape } from './SideShape';
import { ColorCodingMap, mapEdgeDamagesToPoints, mapSideDamagesToPoints } from './pointUtils';
import { SelectionRange, SelectionRangeProps } from './SelectionRange';
import { PlotTooltip, PlotTooltipProps } from './PlotTooltip';

type BladeDamagePlotProps = {
  bladeSide: BladeSide;
  visible: boolean;
  damages: AtlasGqlHorizonDamage[];
  propagations: AtlasGqlHorizonDamagePropagation[];
  damageColumns: TTableColumnDef[];
  loading: boolean;
  shapeWidths: number[];
  bladeLength: number;
  xAxisMax?: number; // if provided, x-axis range will be 0 - xAxisMax, otherwise 0 - bladeLength
  yAxisMax?: number; // if provided, y-axis range will be 0 - yAxisMax, otherwise 0 - max(shapeWidths)
  colorCodingAttribute?: ColorCodingAttribute;
  colorCodingMap?: ColorCodingMap;
  allowAreaSelection: boolean;
  onAreaSelection: (params: {
    bladeSide: BladeSide | null;
    distanceRange: [number, number] | null;
    chordRange?: [number, number] | null;
  }) => void;
};

const [width, height] = [1000, 100];
const [marginTop, marginRight, marginBottom, marginLeft] = [2, 2, 20, 20];

export const BladeDamagePlot: React.FunctionComponent<BladeDamagePlotProps> = ({
  bladeSide,
  visible,
  damages = [],
  propagations = [],
  damageColumns,
  loading,
  shapeWidths,
  bladeLength,
  xAxisMax,
  yAxisMax,
  colorCodingAttribute,
  colorCodingMap,
  allowAreaSelection,
  onAreaSelection,
}) => {
  const plotRef = useRef<SVGSVGElement>(null);
  const brushRef = useRef<SVGGElement>(null);
  const [selectionRange, setSelectionRange] =
    useState<
      Pick<
        SelectionRangeProps,
        'distance' | 'chord' | 'rawSelection' | 'anchorX' | 'anchorY' | 'textAnchor'
      >
    >();
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
  const [tooltipState, setTooltipState] =
    useState<Pick<PlotTooltipProps, 'damages' | 'position'>>();

  useEffect(() => {
    // Clear selection range element when corresponding filter is cleared
    if (!allowAreaSelection) {
      setSelectionRange(undefined);
    }
  }, [allowAreaSelection]);

  const showTooltip = !allowAreaSelection || selectionRange;

  // Projects a set of blade shape widths by percent radial distance to [x,y] coordinate pairs
  const { shapePoints, maxBladeWidth }: { shapePoints: [number, number][]; maxBladeWidth: number } =
    useMemo(() => {
      const maxBladeWidth = yAxisMax ?? Math.max(...shapeWidths);
      return {
        shapePoints: [BladeSide.LeadingEdge, BladeSide.TrailingEdge].includes(bladeSide)
          ? []
          : shapeWidths.map((w, i) => [(i * bladeLength) / 100, (100 * w) / maxBladeWidth]),
        maxBladeWidth,
      };
    }, [shapeWidths, bladeSide, yAxisMax]);

  // Create d3 scales for radial distance (x) and chord % (y)
  const xScale = useMemo(
    () =>
      d3
        .scaleLinear()
        .domain([0, xAxisMax ?? bladeLength])
        .range([marginLeft, width - marginRight]),
    [bladeLength, xAxisMax, width]
  );
  const yScale = useMemo(
    () =>
      d3
        .scaleLinear()
        .domain([0, 100])
        .range(
          bladeSide === BladeSide.SuctionSide
            ? [marginTop, height - marginBottom]
            : [height - marginBottom, marginTop]
        ),
    [bladeSide, height]
  );

  // Once the brush element exists, set up its handler function
  useEffect(() => {
    if (brushRef.current) {
      // Returns the width of the blade at a given distance (for calculating chord)
      const getWidthAtDistance = (distance: number): number =>
        shapeWidths[Math.round((100 * distance) / bladeLength)];

      const xRange = xScale.range();
      const yRange = yScale.range();

      // Given a d3 brush selection, returns corresponding distance and chord ranges
      const getDistanceAndChordRange = (
        selection: d3.BrushSelection
      ): {
        distance: [number, number];
        chord?: [number, number];
      } => {
        const [[distanceMin, _yMin], [distanceMax, _yMax]] = (
          selection as [[number, number], [number, number]]
        ).map(([x, y]) => [
          clamp(xScale.invert(x), 0, bladeLength),
          clamp(yScale.invert(y), 0, 100),
        ]);

        // account for inverted y-axis on pressure side
        const yMin = bladeSide === BladeSide.PressureSide ? _yMax : _yMin;
        const yMax = bladeSide === BladeSide.PressureSide ? _yMin : _yMax;

        if ([BladeSide.PressureSide, BladeSide.SuctionSide].includes(bladeSide)) {
          // Calculate chord range from y-axis pixel range and the blade width arrays
          const [widthAtDistanceMin, widthAtDistanceMax] = [distanceMin, distanceMax].map(
            getWidthAtDistance
          );

          const chordMin =
            clamp(
              Math.min(
                yMin / (widthAtDistanceMin / maxBladeWidth),
                yMin / (widthAtDistanceMax / maxBladeWidth)
              ),
              0,
              100
            ) || 0; // 0 fallback prevents setting to NaN at edge of svg
          const chordMax =
            clamp(
              Math.max(
                yMax / (widthAtDistanceMin / maxBladeWidth),
                yMax / (widthAtDistanceMax / maxBladeWidth)
              ),
              0,
              100
            ) || 100; // 100 fallback prevents setting to NaN at edge of svg

          return {
            distance: [distanceMin, distanceMax],
            chord: [chordMin, chordMax],
          };
        } else {
          return {
            distance: [distanceMin, distanceMax],
          };
        }
      };

      /**
       * Handler for area selection: gets the distance and chord (if PS or SS) range of the selection
       * and passes these, along with the blade side, to the area selection handler function in the container
       */
      const areaSelection = d3
        .brush()
        .extent([
          [xRange[0], yRange[bladeSide === BladeSide.SuctionSide ? 0 : 1]],
          [xRange[1], yRange[bladeSide === BladeSide.SuctionSide ? 1 : 0]],
        ])
        /**
         * As the user drags the selection, calculate the distance/chord ranges to render and where to render
         * them (either top-right or top-left) corner of the selected area.
         */
        .on('brush', ({ selection }: d3.D3BrushEvent<unknown>) => {
          if (selection) {
            let anchorX = 0,
              anchorY = 0;
            let textAnchor: SelectionRangeProps['textAnchor'] = 'start';
            if (brushRef.current) {
              const children = [...brushRef.current.childNodes];

              const topRight = children.find(node =>
                [...(node as SVGRectElement).classList].includes('handle--ne')
              ) as SVGRectElement;

              if (topRight) {
                anchorX = topRight.x.baseVal.value + 8;
                anchorY = topRight.y.baseVal.value;
              }

              if (!topRight || anchorX > 900) {
                const topLeft = children.find(node =>
                  [...(node as SVGRectElement).classList].includes('handle--nw')
                ) as SVGRectElement;

                if (topLeft) {
                  const x = topLeft.x.baseVal.value;
                  const y = topLeft.y.baseVal.value;

                  anchorX = x < 110 ? x + 8 : x - 4;
                  anchorY = x < 110 ? y + 4 : y;
                  textAnchor = x < 110 ? 'start' : 'end';
                }
              }
            }

            const { distance, chord } = getDistanceAndChordRange(selection);

            setSelectionRange({
              distance,
              chord,
              rawSelection: selection as [[number, number], [number, number]],
              anchorX,
              anchorY,
              textAnchor,
            });
          } else {
            setSelectionRange(undefined);
          }
        })
        /**
         * When the user finishes selection, calculate the distance/chord ranges to apply as filters for
         * the plot and the table.
         */
        .on('end', ({ selection }: d3.D3BrushEvent<unknown>) => {
          if (selection) {
            const { distance, chord } = getDistanceAndChordRange(selection);

            onAreaSelection({
              bladeSide,
              distanceRange: distance,
              chordRange: chord,
            });
          } else {
            onAreaSelection({
              bladeSide: null,
              distanceRange: null,
              chordRange: null,
            });
          }
        });

      d3.select<SVGElement, unknown>(brushRef.current).call(areaSelection);
    }
  }, [allowAreaSelection, brushRef.current, xScale, yScale, bladeSide, damages]);

  /**
   * Map damages with distance and chord attributes to svg shapes at the correct [x,y] coordinates.
   * Any damages without distance, and any PS/SS damages without chord will be omitted.
   * (see ./pointUtils for detail)
   */
  const damagePoints: JSX.Element[] = useMemo(
    () =>
      damages
        .map(
          [BladeSide.LeadingEdge, BladeSide.TrailingEdge].includes(bladeSide)
            ? mapEdgeDamagesToPoints({
                xScale,
                yScale,
                colorCodingAttribute,
                colorCodingMap,
                propagations,
                selectedDamageIds: showTooltip ? tooltipState?.damages?.map(d => d.id) : [],
              })
            : mapSideDamagesToPoints({
                widths: shapeWidths,
                maxWidth: maxBladeWidth,
                length: bladeLength,
                xScale,
                yScale,
                colorCodingAttribute,
                colorCodingMap,
                propagations,
                selectedDamageIds: showTooltip ? tooltipState?.damages?.map(d => d.id) : [],
              })
        )
        .filter((point): point is JSX.Element => !!point),
    [
      damages,
      propagations,
      bladeSide,
      xScale,
      yScale,
      colorCodingAttribute,
      colorCodingMap,
      showTooltip,
      tooltipState,
      shapeWidths,
      maxBladeWidth,
      bladeLength,
    ]
  );

  // get damages in range
  const getTooltipDamages = useCallback(
    (position?: { x: number; y: number }) => {
      if (position) {
        // get position
        const { x, y } = position;

        // get range
        const xRange = { min: x - 5, max: x + 5 };
        const yRange = { min: y - 5, max: y + 5 };

        // find points in range
        const damagesInRange = damagePoints
          .filter(point => {
            const { x: xPoint, y: yPoint } = point.props;
            return (
              xPoint >= xRange.min &&
              xPoint <= xRange.max &&
              yPoint >= yRange.min &&
              yPoint <= yRange.max
            );
          })
          .map(point => {
            const damageId = point.props.damageId;
            return damages.find(d => d.id === damageId);
          })
          .filter((d): d is AtlasGqlHorizonDamage => !!d);

        return damagesInRange;
      }

      return [];
    },
    [damages, damagePoints]
  );

  // handler for damage tooltip position
  useEffect(() => {
    if (plotRef.current) {
      const svg = d3.select(plotRef.current);

      // Hover events
      svg.on('mousemove', event => {
        // set initial tootip properties
        if (!tooltipOpen) {
          // get the mouse position relative to the SVG
          const [x, y] = d3.pointer(event);

          // get damages in range
          const damages = getTooltipDamages({ x, y });

          // set damages
          if (damages.length > 0) {
            setTooltipState({
              damages,
              position: { x, y },
            });
          }
        }
      });
    }
  }, [tooltipOpen, getTooltipDamages]);

  // handler for damage tooltip damages
  useEffect(() => {
    // update tooltip properties
    if (tooltipOpen && tooltipState) {
      // get existing damages and position
      const { damages, position } = tooltipState;

      // get damages for tooltip
      const updatedDamages = getTooltipDamages(position);

      // update damages
      if (updatedDamages.length !== damages?.length) {
        setTooltipState({
          damages: updatedDamages,
          position,
        });
      }
    }
  }, [tooltipOpen, tooltipState, getTooltipDamages]);

  return (
    <svg
      ref={plotRef}
      key={bladeSide}
      viewBox={`0 0 ${width} ${height}`}
      className={visible ? '' : 'hidden'}
      opacity={loading || !!damagePoints.length ? 1 : 0.5}
    >
      {!!shapePoints.length &&
      [BladeSide.PressureSide, BladeSide.SuctionSide].includes(bladeSide) ? (
        <SideShape xScale={xScale} yScale={yScale} points={shapePoints} bladeSide={bladeSide} />
      ) : (
        <EdgeShape />
      )}
      <Axes
        xScale={xScale}
        yScale={yScale}
        bladeSide={bladeSide}
        maxWidth={maxBladeWidth}
        height={height}
      />
      <g fill="none" stroke="currentColor" strokeWidth="1.5">
        {...damagePoints}
      </g>
      <g transform={`translate(54, 12)`} fill="#0000008C">
        <text
          style={{
            fontSize: '10px',
            textAnchor: 'middle',
          }}
        >
          {bladeSide}
        </text>
      </g>
      {!loading && !damagePoints.length && (
        <g transform={`translate(${width / 2}, 46)`} fill="#0000008C">
          <text
            style={{
              fontSize: '14px',
              textAnchor: 'middle',
            }}
          >
            No Data
          </text>
        </g>
      )}
      {allowAreaSelection && (
        <>
          <g className="brush" ref={brushRef} />
          {selectionRange && (
            <SelectionRange
              {...selectionRange}
              xScale={xScale}
              yScale={yScale}
              shapePoints={shapePoints}
              bladeLength={bladeLength}
              bladeSide={bladeSide}
            />
          )}
        </>
      )}
      {showTooltip && (
        <PlotTooltip {...tooltipState} damageColumns={damageColumns} onChange={setTooltipOpen} />
      )}
    </svg>
  );
};
