import { useState } from 'react';
import styled from 'styled-components';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Select, Divider } from 'antd';
import { useApolloContext, useQuery, useMutation } from 'utils/apollo';
import { TAG_CREATE, TAG_DELETE } from 'utils/access-control/rules';
import { RenderWithAccess } from 'utils/usePermissions';
import { Button, Spinner } from 'components/ui';
import GET_TAGS_CHOOSER from './GetTagsChooser.gql';
import ADD_TAG from './AddTag.gql';
import REMOVE_TAG from './RemoveTag.gql';

const StyledSelect = styled(Select)`
  min-width: 10rem;
  .ant-select-selection-item-content {
    button {
      display: none;
    }
  }
`;

const StyledMenu = styled.div`
  .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-selected) {
    .ant-select-selected-icon {
      color: transparent !important;
    }
  }
`;

const StyledOption = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default function TagChooser({ ...props }) {
  const { papiClient } = useApolloContext();
  const [removeId, setRemoveId] = useState(null);
  const { data, loading } = useQuery(GET_TAGS_CHOOSER, { client: papiClient });
  const tags = data?.tags ?? [];

  const useTagMutation = mutation =>
    useMutation(mutation, {
      client: papiClient,
      refetchQueries: [{ query: GET_TAGS_CHOOSER }],
      awaitRefetchQueries: true,
    });
  const [removeTag, { loading: deleteLoading }] = useTagMutation(REMOVE_TAG);
  const [addTag, { loading: addLoading }] = useTagMutation(ADD_TAG);

  async function add(tag) {
    await addTag({
      variables: {
        tagValue: tag,
      },
    });
  }

  async function remove(e, tagId) {
    e.stopPropagation();
    setRemoveId(tagId);
    await removeTag({
      variables: {
        id: tagId,
      },
    });
  }

  return (
    <StyledSelect
      mode="multiple"
      placeholder="Add Tags..."
      loading={loading}
      notFoundContent="No existing tags found."
      dropdownMatchSelectWidth={false}
      dropdownRender={menu => {
        const { options, searchValue } = menu.props;
        const matchedOptions = options.map(item => item.value);
        const newEntry = !matchedOptions.includes(searchValue) && searchValue;
        return (
          <StyledMenu>
            {menu}
            {newEntry && (
              <RenderWithAccess rules={[TAG_CREATE]}>
                <>
                  <Divider style={{ margin: '0' }} />
                  {addLoading ? (
                    <Spinner />
                  ) : (
                    <Button
                      _version={4}
                      style={{ margin: '0.25rem' }}
                      onMouseDown={e => e.preventDefault()}
                      onClick={() => add(searchValue)}
                    >
                      <PlusOutlined /> Create "{searchValue}" tag
                    </Button>
                  )}
                </>
              </RenderWithAccess>
            )}
          </StyledMenu>
        );
      }}
      {...props}
    >
      {tags.map(({ id, tag }) => (
        <Select.Option key={id} value={tag}>
          <StyledOption>
            {tag}
            <RenderWithAccess rules={[TAG_DELETE]}>
              <Button
                _version={4}
                onClick={e => remove(e, id)}
                style={{ padding: '0 0.5rem', height: '20px' }}
                loading={deleteLoading && id === removeId}
              >
                <CloseOutlined
                  style={{
                    fontSize: '10px',
                    lineHeight: '10px',
                    verticalAlign: 'middle',
                    marginTop: '-4px',
                  }}
                />
              </Button>
            </RenderWithAccess>
          </StyledOption>
        </Select.Option>
      ))}
    </StyledSelect>
  );
}
