import { useCallback, useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
import { usePermissions } from 'utils/usePermissions';
import { WORK_ORDER_COLLABORATOR } from 'utils/access-control/roles';
import { useMessage } from 'components/ui';

function usePageNotFoundErrorMessage() {
  const { hasRole } = usePermissions();
  const isWoCollaborator = hasRole(WORK_ORDER_COLLABORATOR);
  const message = useMessage();

  return useCallback(
    (errorMessage: string = 'Sorry, the requested URL does not exist.') => {
      message.destroyAll();
      message.error(
        isWoCollaborator
          ? 'Sorry, the requested URL does not exist or you tried to access a route that is not permitted for your user role.'
          : errorMessage
      );
    },
    [message, isWoCollaborator]
  );
}

function usePageNotFoundRedirectUrl(redirectUrl?: string) {
  const { hasRole } = usePermissions();
  const isWoCollaborator = hasRole(WORK_ORDER_COLLABORATOR);
  const lastLocation = useLastLocation();

  let cleanLastLocation = lastLocation?.pathname;
  if (cleanLastLocation && cleanLastLocation.startsWith('/o/')) {
    cleanLastLocation = undefined; // don't redirect to org routes, use the default redirect
  }

  const defaultRedirect = isWoCollaborator ? '/work-orders' : '/';

  return redirectUrl || cleanLastLocation || defaultRedirect;
}

interface IPageNotFoundRedirectProps {
  errorMessage?: string;
  redirectUrl?: string;
}

const PageNotFoundRedirect: React.FunctionComponent<IPageNotFoundRedirectProps> = ({
  errorMessage = 'Sorry, the requested URL does not exist.',
  redirectUrl,
}) => {
  const displayErrorMessage = usePageNotFoundErrorMessage();
  const redirectTo = usePageNotFoundRedirectUrl(redirectUrl);

  useEffect(() => {
    displayErrorMessage(errorMessage);
  }, [displayErrorMessage, errorMessage]);

  return <Redirect to={redirectTo} />;
};

export default PageNotFoundRedirect;

interface UsePageNotFoundRedirectCallbackProps extends IPageNotFoundRedirectProps {}

export function usePageNotFoundRedirect() {
  const { push } = useHistory();
  const displayErrorMessage = usePageNotFoundErrorMessage();
  const redirectTo = usePageNotFoundRedirectUrl();

  return useCallback(
    ({ errorMessage, redirectUrl }: UsePageNotFoundRedirectCallbackProps) => {
      displayErrorMessage(errorMessage);
      push(redirectUrl ?? redirectTo);
    },
    [displayErrorMessage, push, redirectTo]
  );
}
