import { useState, useEffect, useContext, useMemo } from 'react';
import { isEmpty, orderBy } from 'lodash';
import { ItemType } from 'antd5/es/menu/hooks/useItems';
import { DownOutlined, DeleteOutlined, CheckCircleTwoTone } from '@ant-design/icons';

import { useAccountContext } from 'utils/account/AccountContext';
import { AtlasGqlTableView, useTableViewsQuery, useTableViewLazyQuery } from 'types/atlas-graphql';

import { Button, Dropdown, Modal, Tooltip } from 'components/ui';

import { DataTableContext } from 'components/DataTable';
import { tableStorage } from 'components/DataTable/utils';
import { TDropdownAction, TDataTableStorageKey } from 'components/DataTable/types';

import { ColumnPicker } from './ColumnPicker';
import { columnPickerStorage, updateCustomCols } from './ColumnPicker/utils';
import { MultiSorter } from './MultiSorter';
import { multiSorterStorage, updateSort } from './MultiSorter/utils';
import { TExtensionColumn, TMultiSortColumn } from './types';
import { TDisplayColumnData } from 'components/data/types';
import { ConditionsModal, ConditionsState } from '../Views/Conditions';
import { SaveViewModal, SaveViewState } from './SaveView';
import { DeleteViewModal, DeleteViewState } from './DeleteView';
import {
  updateView,
  isViewAndTableStateEqual,
  mapColumns,
  mapMultiSorts,
  removeDefaultFilters,
} from './utils';

import COLORS from 'utils/color/definitions';
import { StyledRow, StyledTitle, StyledButton } from './Views.style';

type ViewsProps = {
  tableId?: string;
  columnDefs: TDisplayColumnData[];
  storageKey?: TDataTableStorageKey;
  showSavedViews: boolean;
  showColumnPicker: boolean;
  showMultiSorter: boolean;
  savedViewCustomFields?: any;
  onSavedViewChange?: (tableView: AtlasGqlTableView) => void;
  isPrioritizing?: boolean;
  showClearSortAndFilter: boolean;
  onClearSortAndFilter: () => void;
  onClearSelection: () => void;
  isClearSortAndFilterDisabled: boolean;
  onTableChange: (pagination: any, filters: any, sorter: any, extra?: any) => void;
  additionalItems: TDropdownAction[];
};

export const Views: React.FunctionComponent<ViewsProps> = ({
  tableId,
  columnDefs,
  storageKey,
  showSavedViews,
  showColumnPicker,
  showMultiSorter,
  savedViewCustomFields,
  onSavedViewChange,
  isPrioritizing,
  showClearSortAndFilter,
  onClearSortAndFilter,
  onClearSelection,
  isClearSortAndFilterDisabled,
  onTableChange,
  additionalItems = [],
}) => {
  const [showColumnPickerModal, setShowColumnPickerModal] = useState(false);
  const [showMultiSorterModal, setShowMultiSorterModal] = useState(false);
  const [conditionsState, setConditionsState] = useState<ConditionsState>({
    isOpen: false,
  });
  const [saveViewState, setSaveViewState] = useState<SaveViewState>({
    isOpen: false,
  });
  const [deleteViewState, setDeleteViewState] = useState<DeleteViewState>({
    isOpen: false,
  });

  const { user } = useAccountContext();

  const tableViewStorageManager = tableStorage({ storageKey: `${tableId}_VIEW`, user });
  const columnPickerStorageManager = columnPickerStorage({ storageKey: storageKey?.COLUMN_PICKER });
  const multiSorterStorageManager = multiSorterStorage({ storageKey: storageKey?.MULTI_SORT });

  const {
    pagination,
    filteredInfo: _filteredInfo,
    sortedInfo,
    tableView,
    updateTableView,
    customizedColumnSet,
    updateCustomizedColumnSet,
    multiSortDef,
    updateMultiSortDef,
  } = useContext(DataTableContext);
  const filteredInfo = removeDefaultFilters(tableId, _filteredInfo);

  // checks to see if the view and the table state are different
  const isViewSaved = useMemo(() => {
    return isViewAndTableStateEqual(tableView, {
      filters: filteredInfo,
      sorts: sortedInfo,
      columns: customizedColumnSet,
      multiSorts: multiSortDef,
    });
  }, [tableView, filteredInfo, sortedInfo, customizedColumnSet, multiSortDef]);

  const isDefaultTableState =
    isClearSortAndFilterDisabled && isEmpty(customizedColumnSet) && isEmpty(multiSortDef);

  const { data: tableViewsData, loading: tableViewsLoading } = useTableViewsQuery({
    variables: { tableName: tableId },
    skip: !showSavedViews,
  });

  const [loadTableView, { loading: tableViewLoading }] = useTableViewLazyQuery({
    onCompleted: data => {
      const loadedTableView = data.tableView;
      if (loadedTableView) {
        applyViewToTable(loadedTableView);
      }
    },
  });

  // sets table to given view
  const applyViewToTable = (tableViewToApply?: AtlasGqlTableView) => {
    if (tableViewToApply) {
      const { filters, sorts, columns, multiSorts } = tableViewToApply;

      const sortKey = sorts?.columnKey;
      const appliedSort = sortKey ? { ...sorts, column: { sortKey } } : {};

      // set filters/sorters
      onTableChange({ ...pagination, current: 1 }, filters, appliedSort);
      // set customized columns
      updateCustomCols(columnDefs, columns, updateCustomizedColumnSet, columnPickerStorageManager);
      // set multisort
      updateSort(columnDefs, multiSorts, updateMultiSortDef, multiSorterStorageManager);

      // update view
      updateView(tableViewToApply, updateTableView, tableViewStorageManager);
      onSavedViewChange && onSavedViewChange(tableViewToApply);
    }
    onClearSelection();
  };

  // saves changes to the current view
  const handleSave = () => {
    setSaveViewState({
      isOpen: true,
      tableId,
      tableView: {
        filters: filteredInfo,
        sorts: sortedInfo,
        columns: (customizedColumnSet ?? []).map(mapColumns),
        multiSorts: (multiSortDef ?? []).map(mapMultiSorts),
        customFields: savedViewCustomFields,
      },
      columnDefs,
    });
  };

  // shows the conditions modal
  const handleConditions = () => {
    setConditionsState({
      isOpen: true,
      tableView: {
        filters: filteredInfo,
        sorts: sortedInfo,
        columns: (customizedColumnSet ?? []).map(mapColumns),
        multiSorts: (multiSortDef ?? []).map(mapMultiSorts),
      },
      columnDefs,
    });
  };

  // Load table view from localStorage and apply
  useEffect(() => {
    if (showSavedViews) {
      const tableView = tableViewStorageManager.get();
      if (tableView) {
        updateView(tableView, updateTableView, tableViewStorageManager);
      }
    }
  }, [showSavedViews]);

  const stringifiedColumnKeys = useMemo(
    () => JSON.stringify(columnDefs.map(({ key }) => key)),
    [columnDefs]
  );

  /* Multisort ****************/
  // Load multi sort from localStorage and apply
  useEffect(
    () => {
      if (showMultiSorter) {
        const savedSort = multiSorterStorageManager.get();
        if (savedSort) {
          updateSort(columnDefs, savedSort, updateMultiSortDef, multiSorterStorageManager);
        }
      }
    },
    [showMultiSorter, stringifiedColumnKeys] // TODO: see if this works on initial render only - it should
  );

  const handleCloseMultiSorterModal = ({
    sortedColumns,
  }: {
    sortedColumns?: TMultiSortColumn[];
  }) => {
    if (sortedColumns) {
      updateSort(columnDefs, sortedColumns, updateMultiSortDef, multiSorterStorageManager);
    }
    setShowMultiSorterModal(false);
  };

  /* Column Picker ************/
  // Load custom columns from localStorage and apply
  useEffect(() => {
    if (showColumnPicker) {
      const savedColumns = columnPickerStorageManager.get();
      if (savedColumns) {
        updateCustomCols(
          columnDefs,
          savedColumns,
          updateCustomizedColumnSet,
          columnPickerStorageManager,
          true
        );
      }
    }
  }, [showColumnPicker, stringifiedColumnKeys]);

  const handleCloseColumnPickerModal = ({
    orderedColumns,
  }: {
    orderedColumns?: TExtensionColumn[];
  }) => {
    if (orderedColumns) {
      updateCustomCols(
        columnDefs,
        orderedColumns,
        updateCustomizedColumnSet,
        columnPickerStorageManager
      );
    }
    setShowColumnPickerModal(false);
  };

  // menu items for 'Views' dropdown
  const menuItems: ItemType[] = [
    showColumnPicker && {
      label: 'Customize Columns',
      onClick: () => setShowColumnPickerModal(true),
      disabled: isPrioritizing,
    },
    showMultiSorter && {
      label: 'Multiple Sort',
      onClick: () => setShowMultiSorterModal(true),
    },
    {
      label: 'Conditions',
      onClick: handleConditions,
      disabled: isDefaultTableState,
    },
    showClearSortAndFilter && {
      label: 'Clear Filter & Sort',
      onClick: onClearSortAndFilter,
      disabled: isClearSortAndFilterDisabled,
    },
    ...(showSavedViews
      ? [
          { type: 'divider' },
          {
            label: `Save View As`,
            disabled: isDefaultTableState || isViewSaved,
            onClick: handleSave,
          },
          ...orderBy(
            tableViewsData?.tableViews ?? [],
            [view => view.name.toLowerCase()],
            ['asc']
          ).map(savedView => {
            const { id: savedViewId, name: savedViewName } = savedView;
            return {
              label: (
                <StyledRow>
                  <StyledTitle onClick={() => loadTableView({ variables: { id: savedViewId } })}>
                    {savedViewId === tableView?.id && (
                      <CheckCircleTwoTone twoToneColor={COLORS.GREEN} />
                    )}
                    <span>{savedViewName}</span>
                  </StyledTitle>
                  <Tooltip title={'Delete View'}>
                    <StyledButton
                      icon={<DeleteOutlined />}
                      onClick={() =>
                        setDeleteViewState({ isOpen: true, tableId, tableView: savedView })
                      }
                    />
                  </Tooltip>
                </StyledRow>
              ),
              disabled: false,
            };
          }),
        ]
      : []),
    ...additionalItems.map(item => {
      const { content, onClick, disabled, hoverable = true } = item;
      return {
        label: content,
        onClick,
        disabled,
        ...(hoverable ? {} : { className: 'not-hoverable' }),
      };
    }),
  ]
    .filter(Boolean)
    .map((item, index) => {
      return { ...item, key: index };
    });

  const isLoading = tableViewsLoading || tableViewLoading;

  return (
    <>
      <Dropdown menu={{ items: menuItems }} trigger={['click']} placement="bottomLeft">
        <Button data-testid="datatable-views-button" loading={isLoading}>
          Views <DownOutlined />
        </Button>
      </Dropdown>
      <ConditionsModal
        conditionsState={conditionsState}
        onClose={() => setConditionsState({ isOpen: false })}
      />
      {showColumnPicker && (
        <Modal
          open={showColumnPickerModal}
          closable={false}
          title="Customize Columns"
          onCancel={() => setShowColumnPickerModal(false)}
          destroyOnClose
          footer={null}
        >
          <ColumnPicker
            columns={columnDefs}
            customizedColumnSet={customizedColumnSet}
            onClose={handleCloseColumnPickerModal}
          />
        </Modal>
      )}
      {showMultiSorter && (
        <Modal
          open={showMultiSorterModal}
          closable={false}
          title="Multiple Sort"
          onCancel={() => setShowMultiSorterModal(false)}
          destroyOnClose
          footer={null}
        >
          <MultiSorter
            columnDefs={columnDefs}
            multiSortDef={multiSortDef}
            onClose={handleCloseMultiSorterModal}
          />
        </Modal>
      )}
      {showSavedViews && (
        <>
          <SaveViewModal
            saveViewState={saveViewState}
            onClose={() => setSaveViewState({ isOpen: false })}
            onSuccess={savedView => {
              if (savedView) {
                applyViewToTable(savedView);
              }
            }}
          />
          <DeleteViewModal
            deleteViewState={deleteViewState}
            onClose={() => setDeleteViewState({ isOpen: false })}
            onSuccess={deletedView => {
              if (tableView?.id === deletedView?.id) {
                updateView(undefined, updateTableView, tableViewStorageManager);
              }
            }}
          />
        </>
      )}
    </>
  );
};
