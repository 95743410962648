import { useRef } from 'react';
import { isFinite } from 'lodash';
import { Input, Form, Switch } from 'antd';
import { Button, Tooltip } from 'components/ui';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { DAMAGE_EDIT_ATTRIBUTES } from 'utils/access-control/rules';
import { usePermissions } from 'utils/usePermissions';
import { useFeatures } from 'utils/features';
import styled from 'styled-components';
import { renderColumn } from 'components/data/helpers';
import { editable } from 'utils/editable';
import { REALTIME_POTENTIAL_ATTRIBUTE_KEYS } from 'components/damages/constants';
import { AtlasGqlDamageSchemaAttributeState } from 'types/atlas-graphql';
import DamageEditSchema from './DamageEditSchema';
import { useIsInView } from 'utils/hooks';

const { TextArea } = Input;

const FormContainer = styled.div`
  max-height: 100%;
  overflow-y: auto;

  ${({ $isScrolledToTop }) =>
    !$isScrolledToTop &&
    `
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: inset 0px 5px 5px -3px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: inset 0px 5px 5px -3px rgba(0, 0, 0, 0.2);
    box-shadow: inset 0px 5px 5px -3px rgba(0, 0, 0, 0.2);
  `}

  form {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .form-fields-container {
      height: 100%;
      overflow-y: auto;
    }
  }
`;

const StyledForm = styled(Form)`
  width: 100%;

  .ant-form-item {
    margin-bottom: 1rem;
    .ant-form-item-control {
      text-align: start;
    }
    .ant-form-item-control-input-content {
      display: flex;
      flex-direction: column;
      text-align: start;

      &:has(.ant-switch) {
        display: block;
      }
    }
  }
`;

const HintText = styled.span`
  color: ${({ theme }) => theme.gray};
`;

const FixedButtonContainer = styled.div`
  background-color: ${({ backgroundColor }) => backgroundColor ?? 'white'};
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: ${({ $isImageViewer }) => ($isImageViewer ? '12px' : '12px 12px 0 12px')};

  display: flex;
  align-items: flex-start;
  justify-content: flex-end;

  ${({ $isScrolledToBottom }) =>
    !$isScrolledToBottom &&
    `
    -webkit-box-shadow: 0px -5px 5px -3px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px -5px 5px -3px rgba(0, 0, 0, 0.2);
    box-shadow: 0px -5px 5px -3px rgba(0, 0, 0, 0.2);
  `}

  button {
    width: 40%;
  }
`;

const StyledButton = styled(Button)`
  margin-right: 12px;
  display: block;
`;

const formItemLayout = {
  horizontal: {
    labelCol: {
      xs: { span: 12 },
    },
    wrapperCol: {
      xs: { span: 12 },
    },
  },
  vertical: {
    wrapperCol: {
      xs: { span: 24 },
    },
  },
};

function DisabledExtra({ name }) {
  return (
    <Tooltip title={`"${name}" will be removed after saving. Attribute is no longer in the schema`}>
      <div style={{ color: 'red', fontSize: 10, whiteSpace: 'normal' }}>
        {`* "${name}" will be removed`}
      </div>
    </Tooltip>
  );
}

function InvalidExtra() {
  return <div style={{ color: 'red', fontSize: 10, whiteSpace: 'normal' }}>Invalid Selection</div>;
}

const HelpTooltip = styled(Tooltip)`
  margin-right: 0.2rem;
`;

function stopPropagation(e) {
  e.stopPropagation();
}

export function AttributesForm({
  selections,
  saveButtonText,
  showComment = true,
  columns,
  onChange,
  loading,
  saving,
  saveDisabled,
  onSave,
  onCancel,
  formLayout,
  canChangeSchema = true,
  schemaId,
  setSchemaId,
  canChangeCritical = false,
  critical,
  setCritical,
  includesRealtimePotentialAttrs,
  potentialAttrs,
  backgroundColor,
  isImageViewer = false,
}) {
  const topRef = useRef(null);
  const bottomRef = useRef(null);
  const isScrolledToTop = useIsInView(topRef);
  const isScrolledToBottom = useIsInView(bottomRef);
  const { REQUIRE_VALID_DAMAGE } = useFeatures().features;
  const { canAccess } = usePermissions();
  const isEditable = canAccess([DAMAGE_EDIT_ATTRIBUTES]);

  return (
    <FormContainer $isScrolledToTop={isScrolledToTop}>
      <StyledForm id="create-new-damage-attributes-form" layout={formLayout} onFinish={onSave}>
        <div className="form-fields-container">
          <div ref={topRef} />
          {canChangeSchema && <DamageEditSchema schemaId={schemaId} onChange={setSchemaId} />}
          {canChangeCritical && (
            <Form.Item
              {...formItemLayout[formLayout]}
              label="Critical"
              name="critical"
              initialValue={critical}
            >
              <Switch
                data-testid="critical-input"
                checked={critical}
                onChange={setCritical}
                disabled={!isEditable}
              />
            </Form.Item>
          )}
          {columns.map(col => {
            const { name, valid, state, disabled, description, unit, filters, type } = col;

            const isRealtimeAttribute =
              includesRealtimePotentialAttrs &&
              REALTIME_POTENTIAL_ATTRIBUTE_KEYS.includes(name) &&
              isFinite(potentialAttrs[name]);

            const [min, max] = filters;
            const tooltip = description && (
              <HelpTooltip title={description}>
                <QuestionCircleOutlined />
              </HelpTooltip>
            );
            return (
              <Form.Item
                {...formItemLayout[formLayout]}
                key={name}
                label={
                  <span>
                    {tooltip}
                    {name}
                    {unit && <span style={{ paddingLeft: '0.2rem' }}>({unit})</span>}
                  </span>
                }
                extra={
                  !valid && !loading ? (
                    <InvalidExtra />
                  ) : state === AtlasGqlDamageSchemaAttributeState.Disabled && !loading ? (
                    <DisabledExtra name={name} />
                  ) : null
                }
              >
                {renderColumn(editable(col, { onChange }), {
                  attrs: selections,
                  disabled,
                  editable: isEditable && !isRealtimeAttribute,
                  REQUIRE_VALID_DAMAGE,
                  allowClear: true,
                  ...(col.unit ? { suffix: col.unit } : {}),
                  loading: loading && !Object.keys(selections).includes(name),
                })}
                <HintText>
                  {isRealtimeAttribute && 'This value is calculated by the bounding box'}
                  {!isRealtimeAttribute &&
                    type === 'number' &&
                    `Range: ${!!Number(min?.value) ? min.value : 0} ${
                      !!Number(max?.value) ? `to ${max.value}` : 'and up'
                    }`}
                </HintText>
              </Form.Item>
            );
          })}
          {showComment && (
            <Form.Item {...formItemLayout[formLayout]} label="Comment" name="comment">
              <TextArea
                id="comment-text-input"
                data-testid="comment-text-input"
                disabled={!isEditable}
                rows={2}
                onKeyDown={stopPropagation}
                onKeyUp={stopPropagation}
              />
            </Form.Item>
          )}
          <div ref={bottomRef} />
        </div>
        {onSave && (
          <FixedButtonContainer
            $isScrolledToBottom={isScrolledToBottom}
            backgroundColor={backgroundColor}
            $isImageViewer={isImageViewer}
          >
            <StyledButton
              _version={4}
              data-testid="damage-saveBtn"
              disabled={saveDisabled}
              htmlType="submit"
              loading={saving}
              role="saveButton"
              type="primary"
            >
              {saveButtonText}
            </StyledButton>
            <Button _version={4} data-testid="damage-cancelBtn" onClick={onCancel}>
              Cancel
            </Button>
          </FixedButtonContainer>
        )}
      </StyledForm>
    </FormContainer>
  );
}
