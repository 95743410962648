import { ConfirmationModal } from 'components/ConfirmationModal';

import { ApolloError } from '@apollo/client';
import { AtlasGqlTableView, useDeleteTableViewMutation } from 'types/atlas-graphql';
import { useMessage } from 'components/ui';

export type DeleteViewState = {
  isOpen: boolean;
  tableId?: string;
  tableView?: AtlasGqlTableView;
};

type DeleteViewModalProps = {
  deleteViewState: DeleteViewState;
  onClose: () => void;
  onSuccess?: (view: AtlasGqlTableView) => void;
  onError?: (error: ApolloError) => void;
};

export const DeleteViewModal: React.FunctionComponent<DeleteViewModalProps> = ({
  deleteViewState,
  onClose,
  onSuccess,
  onError,
}) => {
  const message = useMessage();
  const { isOpen, tableView } = deleteViewState;

  // create table view mutation
  const [deleteTableView, { loading: deleteTableViewLoading }] = useDeleteTableViewMutation({
    onCompleted: data => {
      const isTableViewDeleted = data.deleteTableView;
      if (!!tableView && isTableViewDeleted) {
        message.success(`${tableView.name} deleted successfully`);
        onSuccess && onSuccess(tableView);
      }

      onClose();
    },
    onError: error => {
      message.error(`There was an error deleting the view. Please try again.`);
      onError && onError(error);

      onClose();
    },
    update: (cache, { data }) => {
      const isTableViewDeleted = data?.deleteTableView;
      if (!!tableView && isTableViewDeleted) {
        const cacheId = cache.identify({
          id: tableView.id,
          __typename: 'TableView',
        });
        cache.evict({ id: cacheId });
      }
    },
  });

  // handle submit logic
  const handleSubmit = () => {
    !!tableView &&
      deleteTableView({
        variables: {
          id: tableView.id,
        },
      });
  };

  return (
    <ConfirmationModal
      open={isOpen && !!tableView}
      title="Delete View"
      okText="Delete"
      onOk={handleSubmit}
      cancelText="Cancel"
      onCancel={onClose}
      message={`This action will delete ${tableView?.name}. Do you wish to continue?`}
      loading={deleteTableViewLoading}
      destroyOnClose
    />
  );
};
