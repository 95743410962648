import { Fragment } from 'react';
import styled from 'styled-components';

import { PageHeaderLayout } from 'components/PageHeaderLayout';
import { FleetMap } from 'components/FleetMap';
import UpcomingTasks from './UpcomingTasks';
import { DamageList } from 'horizon/routes/Inspections/DamageList';
import { useFeatures } from 'utils/features';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { Spinner } from 'components/ui';
import { Collapse } from 'antd';
import { useNeitherDamagesFlagAlert } from 'horizon/components/Damages/useNeitherDamagesFlagAlert';
import { DamageList2 } from '../Inspections/DamageList2';
import { useAccountContext } from 'utils/account/AccountContext';
import { SKYSPECS_ORG } from 'utils/constants';

const Section = styled.div`
  margin: 1em 0;
  &:first-child {
    margin-top: 0;
  }
`;

const StyledCollapsePanel = styled(Collapse.Panel)`
  div.ant-collapse-content-box {
    padding: 0;
  }
`;

const FleetMapHeader = styled.div`
  font-size: 1rem;
`;

export const Home = () => {
  const { customer } = useAccountContext();
  const { features, loading: featuresLoading } = useFeatures();
  const { HORIZON_DAMAGES, LEGACY_DAMAGES } = features;
  useNeitherDamagesFlagAlert();

  return (
    <Fragment>
      <PageHeaderLayout title="Dashboard">
        <Section data-testid="dashboard-damages">
          {featuresLoading ? (
            <Spinner />
          ) : HORIZON_DAMAGES ? (
            <DamageList2
              tableId="attention-required-damage-list-2"
              title="Attention Required"
              critical={true}
              savedViews={false}
            />
          ) : LEGACY_DAMAGES ? (
            <DamageList
              tableId="attention-required-damage-list"
              title="Attention Required"
              critical={true}
              pagination={5}
            />
          ) : null}
        </Section>
        <Section data-testid="dashboard-upcoming-tasks">
          <UpcomingTasks />
        </Section>
        {/* data-private used to hide images from LogRocket: https://docs.logrocket.com/reference/dom */}
        {/* temporarily hide the fleet map for skyspecs due to high tubine count breaking things */}
        {customer?.id !== SKYSPECS_ORG && (
          <Section data-testid="dashboard-fleet-map" data-private="logrocket-hide">
            <Collapse>
              <StyledCollapsePanel
                header={
                  <FleetMapHeader data-testid="dashboard-fleet-map-collapse">
                    Fleet Map
                  </FleetMapHeader>
                }
                key={1}
              >
                <ErrorBoundary>
                  <div data-testid="dashboard-fleet-map-leaflet">
                    {featuresLoading ? <Spinner /> : <FleetMap />}
                  </div>
                </ErrorBoundary>
              </StyledCollapsePanel>
            </Collapse>
          </Section>
        )}
      </PageHeaderLayout>
    </Fragment>
  );
};
