import { Select } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

import { TTableColumnDef } from 'horizon/types/TableColumnDef';
import { TEditColumnData } from 'components/data/types';
import { getFilter, getFilterDropdown, getOnFilter, sortBy, None } from 'components/data/helpers';
import {
  getPrimaryObservationGroupForDamage,
  propagationTypeMapping,
} from 'horizon/components/Damages/utils';
import {
  renderAssetSite,
  renderAssetNameWithAncestors,
} from 'components/data/assets/assetColumnRenderers';
import { DamageLink } from 'horizon/components/Damages/utils';

import {
  AtlasGqlAsset,
  AtlasGqlAssetType,
  AtlasGqlConfirmationStatus,
  AtlasGqlHorizonDamage,
  AtlasGqlHorizonDamageObservation,
  AtlasGqlHorizonDamageObservationGroup,
  AtlasGqlInspection,
  AtlasGqlTask,
} from 'types/atlas-graphql';
import { StatusTag } from 'components/StatusTag';
import { CheckIcon } from 'components/Icons';
import { flatten, startCase } from 'lodash';
import { DamageNumberContainer, HelpTooltip } from './DamageTable2.style';
import {
  TasksPopoverTable,
  ObservationsPopoverTable,
  InspectionsPopoverTable,
} from 'horizon/components/Damages/Popovers';
import {
  DamageIdAndStatusFilter,
  TasksFilter,
  SiteFilter,
  AssetFilter,
  DefaultStateToGraphqlKeyMap,
} from 'components/DataTable/customFilters';

export const damageIdColumn: TTableColumnDef<AtlasGqlHorizonDamage> = {
  key: 'id',
  allKeys: ['confirmationStatus'],
  title: (
    <span>
      Damage ID
      <HelpTooltip
        title={
          'A Damage is a group of one or more Observations. This Damage ID refers to the group. Individual images Observation IDs can be found by hovering over their red bounding box.'
        }
        placement="right"
      >
        <QuestionCircleOutlined />
      </HelpTooltip>
    </span>
  ),
  dataIndex: 'id',
  filterProperty: 'id',
  filterDropdown: props => <DamageIdAndStatusFilter {...props} />,
  defaultFilterOperator: 'CONTAINS',
  render: (_, record) => (
    <DamageNumberContainer>
      <DamageLink damageId={record.id} />
      {record.confirmationStatus === AtlasGqlConfirmationStatus.Confirmed && <CheckIcon />}
    </DamageNumberContainer>
  ),
};

export const tasksColumn: TTableColumnDef<AtlasGqlHorizonDamage> = {
  key: 'tasks',
  allKeys: ['taskCount', 'taskType', 'taskStatus', 'hasUnarchivedTasks', 'hasPrimaryGroupTasks'],
  title: 'Tasks',
  dataIndex: 'tasks',
  filterDropdown: props => <TasksFilter {...props} />,
  sortKey: 'taskCount',
  sorter: sortBy('taskCount'),
  render: (tasks: AtlasGqlTask[]) => <TasksPopoverTable tasks={tasks} />,
};

export const siteColumn: TTableColumnDef<AtlasGqlHorizonDamage> = {
  key: 'site',
  title: 'Site',
  dataIndex: 'asset',
  filterProperty: 'asset.site',
  filterDropdown: props => <SiteFilter {...props} />,
  sortKey: 'site',
  sorter: sortBy('asset.site'),
  render: renderAssetSite,
};

export const getAssetColumn = (
  tableId: string,
  lockedAssetTypes?: AtlasGqlAssetType[],
  lockedAssetIds?: string[]
): TTableColumnDef<AtlasGqlHorizonDamage> => ({
  key: 'asset',
  allKeys: Object.values(DefaultStateToGraphqlKeyMap),
  title: 'Asset',
  dataIndex: 'asset',
  filterProperty: 'asset',
  filtered: !!lockedAssetIds?.length || !!lockedAssetTypes?.length || undefined,
  filterDropdown: props => (
    <AssetFilter
      {...props}
      tableId={tableId}
      lockedAssetTypes={lockedAssetTypes}
      lockedAssetIds={lockedAssetIds}
    />
  ),
  serverSideFilterParsers: {
    assetId: (v: string) => v.split(':')[0],
    componentId: (v: string) => v.split(':')[0],
  },
  sortKey: 'asset',
  sorter: sortBy('asset'),
  render: (asset: AtlasGqlAsset) => renderAssetNameWithAncestors(asset, 'damages2'),
});

export const assetColumn: TTableColumnDef<AtlasGqlHorizonDamage> = {
  key: 'asset',
  title: 'Asset',
  dataIndex: 'asset',
  render: (asset: AtlasGqlAsset) => renderAssetNameWithAncestors(asset, 'damages2'),
};

export const assetTypeColumn: TTableColumnDef<AtlasGqlHorizonDamage> = {
  key: 'assetType',
  title: 'Asset Type',
  dataIndex: 'asset',
  render: (asset: AtlasGqlAsset) => asset?.assetType?.name ?? <None />,
};

export const confirmationStatusColumn: TEditColumnData = {
  key: 'confirmationStatus',
  title: 'Confirmation Status',
  dataIndex: 'confirmationStatus',
  filterProperty: 'confirmationStatus',
  className: 'hidden-column',
  render: (confirmationStatus: string) => confirmationStatus ?? <None />,
  edit(
    record: any,
    { onChange, loading, ...props }: { onChange: (data: any) => void; loading: boolean }
  ) {
    const { confirmationStatus, editable } = record || {};
    const options = [AtlasGqlConfirmationStatus.Confirmed, AtlasGqlConfirmationStatus.NotConfirmed];

    return (
      <Select value={confirmationStatus} {...props} disabled={!editable}>
        {options.map(value => (
          <Select.Option key={value} value={value}>
            {startCase(value)}
          </Select.Option>
        ))}
      </Select>
    );
  },
  editRule: 'DAMAGE_CONFIRM',
};

export const criticalColumn: TEditColumnData = {
  key: 'critical',
  title: 'Critical',
  dataIndex: 'critical',
  filterProperty: 'critical',
  className: 'hidden-column',
  render: (critical: string) => critical ?? <None />,
  edit(
    record: any,
    { onChange, loading, ...props }: { onChange: (data: any) => void; loading: boolean }
  ) {
    const { critical, editable } = record || {};
    const options = [
      { key: 'True', value: true },
      { key: 'False', value: false },
    ];

    return (
      <Select value={critical} {...props} disabled={!editable}>
        {options.map(({ key, value }) => (
          <Select.Option key={key} value={value}>
            {startCase(key)}
          </Select.Option>
        ))}
      </Select>
    );
  },
  editRule: 'DAMAGE_EDIT_ATTRIBUTES',
};

export const getAssetStatusColumn = (
  filterOptions: string[]
): TTableColumnDef<AtlasGqlHorizonDamage> => {
  const assetStatusConfig = {
    key: 'assetStatus',
    type: 'string',
    filterAttr: 'assetStatus',
    exactMatch: false,
    values: [
      ...filterOptions.map(v => ({
        id: v,
        label: v,
      })),
    ],
    includeNoneOption: true,
    noneOptionValue: 'IS_NULL',
  };

  return {
    key: 'assetStatus',
    title: 'Asset Status',
    dataIndex: 'asset',
    filterProperty: 'asset.assetStatus',
    filters: getFilter(assetStatusConfig),
    filterDropdown: getFilterDropdown(assetStatusConfig),
    onFilter: getOnFilter(assetStatusConfig),
    sortKey: 'assetStatus',
    sorter: sortBy('asset.assetStatus'),
    render: (asset: AtlasGqlAsset) =>
      asset?.assetType?.name &&
      !['Site', 'Turbine'].includes(asset.assetType.name) &&
      asset?.assetStatus ? (
        <StatusTag value={asset.assetStatus} />
      ) : (
        <None />
      ),
  };
};

// TODO: remove this during cleanup of `rtv1:damage_sort_filter_improvements`
// (replaced by `getAssetStatusColumn`)
export const assetStatusColumn: TTableColumnDef<AtlasGqlHorizonDamage> = {
  key: 'assetStatus',
  title: 'Asset Status',
  dataIndex: 'asset',
  render: (asset: AtlasGqlAsset) =>
    asset?.assetType?.name &&
    !['Site', 'Turbine'].includes(asset.assetType.name) &&
    asset?.assetStatus ? (
      <StatusTag value={asset.assetStatus} />
    ) : (
      <None />
    ),
};

// TODO: deprecate -> replaced by getInspectionsColumn
export const inspectionsColumn: TTableColumnDef<AtlasGqlHorizonDamage> = {
  key: 'inspections',
  title: 'Inspections',
  dataIndex: 'observationGroups',
  render: (observationGroups: AtlasGqlHorizonDamageObservationGroup[]) => {
    const inspections = observationGroups
      .map(og => og?.observations?.[0]?.inspection)
      .filter((i): i is AtlasGqlInspection => !!i);

    return <InspectionsPopoverTable inspections={inspections} />;
  },
};

export const getInspectionsColumn = (): TTableColumnDef<AtlasGqlHorizonDamage> => {
  const inspectionCountConfig = {
    key: 'inspectionCount',
    type: 'number',
    filterAttr: 'inspectionCount',
    exactMatch: false,
    values: [
      { id: 'min', label: 1 },
      { id: 'max', label: Infinity },
    ],
    roundValue: true,
    step: 1,
    includeSlider: false,
    includeNoneOption: false,
  };

  return {
    key: 'inspectionCount',
    title: 'Inspections',
    dataIndex: 'observationGroups',
    filterProperty: 'inspectionCount',
    filters: getFilter(inspectionCountConfig),
    filterDropdown: getFilterDropdown(inspectionCountConfig),
    onFilter: getOnFilter(inspectionCountConfig),
    sortKey: 'inspectionCount',
    sorter: sortBy('inspectionCount'),
    render: (observationGroups: AtlasGqlHorizonDamageObservationGroup[]) => {
      const inspections = observationGroups
        .map(og => og?.observations?.[0]?.inspection)
        .filter((i): i is AtlasGqlInspection => !!i);

      return <InspectionsPopoverTable inspections={inspections} />;
    },
  };
};

// TODO: deprecate -> replaced by getObservationsColumn
export const observationsColumn: TTableColumnDef<AtlasGqlHorizonDamage> = {
  key: 'observations',
  title: 'Observations',
  dataIndex: 'observationGroups',
  render: (observationGroups: AtlasGqlHorizonDamageObservationGroup[]) => {
    const observations = flatten(observationGroups.map(og => og.observations)).filter(
      (o): o is AtlasGqlHorizonDamageObservation => !!o
    );

    return <ObservationsPopoverTable observations={observations} />;
  },
};

export const getObservationsColumn = (): TTableColumnDef<AtlasGqlHorizonDamage> => {
  const observationCountConfig = {
    key: 'observationCount',
    type: 'number',
    filterAttr: 'observationCount',
    exactMatch: false,
    values: [
      { id: 'min', label: 1 },
      { id: 'max', label: Infinity },
    ],
    roundValue: true,
    step: 1,
    includeSlider: false,
    includeNoneOption: false,
  };

  return {
    key: 'observationCount',
    title: 'Observations',
    dataIndex: 'observationGroups',
    filterProperty: 'observationCount',
    filters: getFilter(observationCountConfig),
    filterDropdown: getFilterDropdown(observationCountConfig),
    onFilter: getOnFilter(observationCountConfig),
    sortKey: 'observationCount',
    sorter: sortBy('observationCount'),
    render: (observationGroups: AtlasGqlHorizonDamageObservationGroup[]) => {
      const observations = flatten(observationGroups.map(og => og.observations)).filter(
        (o): o is AtlasGqlHorizonDamageObservation => !!o
      );

      return <ObservationsPopoverTable observations={observations} />;
    },
  };
};

export const getPropagationsColumn = (): TTableColumnDef<AtlasGqlHorizonDamage> => {
  const propagationTypeConfig = {
    key: 'propagationType',
    type: 'string',
    filterAttr: 'propagationType',
    exactMatch: false,
    values: [
      ...Object.keys(propagationTypeMapping).map(key => ({
        id: key,
        label: propagationTypeMapping[key],
      })),
      { id: '', label: <i>none</i> },
    ],
    includeNoneOption: true,
  };

  return {
    key: 'propagationType',
    title: 'Propagation Type',
    dataIndex: 'propagations',
    filterProperty: 'propagationType',
    filters: getFilter(propagationTypeConfig),
    filterDropdown: getFilterDropdown(propagationTypeConfig),
    onFilter: getOnFilter(propagationTypeConfig),
    // sorter: sortBy('propagationType'),
    render: (_, record) => {
      const primaryGroup = getPrimaryObservationGroupForDamage(record);
      const propagation = (record.propagations ?? []).find(
        propagation => propagation?.targetObservationGroup?.id === primaryGroup?.id
      );

      return propagation?.type ? propagationTypeMapping[propagation.type] : <None />;
    },
  };
};
