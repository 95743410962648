import { useEffect, useMemo } from 'react';
import {
  AtlasGqlOrganizationRelationshipScope,
  useGetOrganizationRelationshipsQuery,
  useGetTrustedOrganizationsQuery,
} from 'types/atlas-graphql';
import { useAccountContext } from 'utils/account/AccountContext';
import { notEmpty } from 'utils/types';
import { useTaskListFilters } from 'utils/useTaskListFilters';
import { useMessage } from 'components/ui';

interface UseOrganizationRelationshipsArgs {
  scope?: AtlasGqlOrganizationRelationshipScope;
  skip?: boolean;
  types?: ('vendor' | 'delivery')[];
}

export function useOrganizationRelationships({
  scope = AtlasGqlOrganizationRelationshipScope.RelationshipOwner,
  skip,
  types,
}: UseOrganizationRelationshipsArgs = {}) {
  const { data, error, loading } = useGetOrganizationRelationshipsQuery({
    skip,
    variables: { scope, types },
  });

  const relationships = data?.organizationRelationships?.records;
  let errors = data?.organizationRelationships?.errors;

  if (!errors && error) {
    errors = [{ message: error.message, __typename: 'UserInputError' }];
  }

  if (loading) {
    return { loading, relationships: [] };
  }

  return { loading, errors, relationships };
}

interface UseOrganizationVendorRelationshipsArgs {
  skip?: boolean;
}

export function useOrganizationVendorRelationships({
  skip,
}: UseOrganizationVendorRelationshipsArgs = {}) {
  const { errors, loading, relationships } = useOrganizationRelationships({
    skip,
    scope: AtlasGqlOrganizationRelationshipScope.RelationshipOwner,
    types: ['vendor'],
  });

  const vendors = relationships?.map(r => r.relationshipPartner).filter(notEmpty) ?? [];
  const errorsString = errors?.map(e => e.message).join('. ');

  return { errors, errorsString, loading, vendors };
}

/**
 * Gets the list of Organizations that are currently vendors to be used for Vendor selection.
 */
export function useVendorOrgRelationshipOptions() {
  const message = useMessage();
  const hasVendorRT = useAccountContext().hasReleaseToggle('vendor-dropdown');
  const { errorsString, loading, vendors } = useOrganizationVendorRelationships({
    skip: !hasVendorRT,
  });

  useEffect(() => {
    if (!loading && errorsString) {
      message.warning(`Unable to load vendors. ${errorsString}`, 8);
    }
  }, [errorsString, loading, message]);

  const vendorOptions = useMemo(
    () =>
      vendors.map(v => ({
        label: v.name,
        text: v.name,
        value: v.id,
      })),
    [vendors]
  );

  return { loading, vendorOptions };
}

/**
 * Gets the list of Organizations that are currently assigned vendors to be used for Task filtering.
 */
export function useVendorOrgFilterOptions() {
  const message = useMessage();
  const hasVendorRT = useAccountContext().hasReleaseToggle('vendor-dropdown');
  const { filters, loading, error } = useTaskListFilters({ skip: !hasVendorRT });
  const vendors = useMemo(() => filters.vendorOrganization ?? [], [filters]);

  useEffect(() => {
    if (!loading && error) {
      message.warning(`Unable to load vendors. ${error.message}`, 8);
    }
  }, [error, loading, message]);

  const vendorOptions = useMemo(
    () =>
      vendors.map(v => ({
        label: v.name,
        text: v.name,
        value: v.name,
      })),
    [vendors]
  );

  return { loading, vendorOptions };
}

interface UseOrganizationDeliveryRelationshipsArgs {
  skip?: boolean;
}

/**
 * Gets a list of organizations that this organization is allowed to deliver to.
 * @param skip - Whether to skip the query.
 */
export function useOrganizationDeliveryCustomers({
  skip,
}: UseOrganizationDeliveryRelationshipsArgs = {}) {
  const hasVendorRT = useAccountContext().hasReleaseToggle('vendor-dropdown');
  const { errors, loading, relationships } = useOrganizationRelationships({
    skip: skip || !hasVendorRT,
    scope: AtlasGqlOrganizationRelationshipScope.RelationshipPartner,
    types: ['delivery'],
  });
  const {
    error: errorsTrustedOrg,
    loading: loadingTrustedOrg,
    data: trustedOrgData,
  } = useGetTrustedOrganizationsQuery({
    skip: skip || hasVendorRT,
  });

  return useMemo(() => {
    if (!hasVendorRT) {
      return {
        loading: loadingTrustedOrg,
        errors: errorsTrustedOrg,
        errorsString: errorsTrustedOrg?.message,
        deliveryCustomers: trustedOrgData?.trustedOrganizations?.filter(notEmpty) ?? [],
      };
    }
    return {
      loading,
      errors,
      errorsString: errors?.map(e => e.message).join('. '),
      deliveryCustomers: relationships?.map(r => r.relationshipOwner).filter(notEmpty) ?? [],
    };
  }, [
    errors,
    hasVendorRT,
    loading,
    loadingTrustedOrg,
    relationships,
    errorsTrustedOrg,
    trustedOrgData,
  ]);
}

interface UseDeliveryOrgOptionsArgs {
  valueKey?: 'id' | 'name';
  skip?: boolean;
  sort?: boolean;
}

export function useDeliveryCustomerOrgOptions({
  valueKey = 'id',
  skip,
  sort,
}: UseDeliveryOrgOptionsArgs = {}) {
  const message = useMessage();
  const { errorsString, loading, deliveryCustomers } = useOrganizationDeliveryCustomers({
    skip,
  });

  useEffect(() => {
    if (!loading && errorsString) {
      message.warning(`Unable to load delivery organizations. ${errorsString}`, 8);
    }
  }, [errorsString, loading, message]);

  const deliveryCustomerOptions = useMemo(() => {
    const temp = deliveryCustomers.map(d => ({
      label: d.name,
      text: d.name,
      value: valueKey === 'name' ? d.name : d.id,
    }));

    if (sort) {
      temp.sort((a, b) => a.label.localeCompare(b.label));
    }

    return temp;
  }, [deliveryCustomers, sort, valueKey]);

  return { loading, deliveryCustomerOptions };
}
