import { useState, useCallback, useMemo, useEffect } from 'react';
import {
  DownOutlined,
  FileOutlined,
  FileExcelOutlined,
  FilePdfOutlined,
  FileZipOutlined,
} from '@ant-design/icons';
import { Modal, Space, Menu, Tag } from 'antd';
import { Button, Dropdown, Tooltip, useMessage } from 'components/ui';
import { keyBy, startCase, lowerCase, flatten, assign } from 'lodash';
import { AdvancedReportForm, SubmitReportTemplateProp } from './AdvancedReportForm';
import { ReportContext, ReportTemplatesForContext } from './types';
import { getAugmentedTemplates, useGetReportTemplatesForContext } from './utils';

import {
  AtlasGql_ReportArgument,
  AtlasGql_CreateReportInput,
  useCreateReportsMutation,
  useCreateReportMutation,
  useGetReportDeliveryModesQuery,
  AtlasGqlReportStatus,
  AtlasGqlReportDeliveryStatus,
} from 'types/atlas-graphql';
import { PollType, usePollingReportQuery } from 'horizon/routes/Reports/usePollingReportQuery';

const extensionLu = {
  pdf: <FilePdfOutlined />,
  json: <FileOutlined />,
  xlsx: <FileExcelOutlined />,
  zip: <FileZipOutlined />,
};

const MenuItem = Menu.Item;

interface Props {
  reportContexts: ReportContext[];
  helpText?: string | null;
  disabled?: boolean;
  cacheKey?: string | null;
}

export function ExportReport({
  disabled = false,
  reportContexts = [],
  helpText = null,
  cacheKey = null,
}: Props) {
  const message = useMessage();
  const [modalVisible, setModalVisible] = useState(false);

  const gqlReportContexts = reportContexts.map(({ context }) => context);
  const contextTemplates = useGetReportTemplatesForContext(gqlReportContexts);

  const { data: deliveryModesData } = useGetReportDeliveryModesQuery();

  const templatesAreNonEmpty = contextTemplates.some(({ templates }) => templates.length);

  const deliveryModes = deliveryModesData?.reportDeliveryModes || [];
  // Setting up to rename the download delivery mode to zip. Download is for single
  // report creation.
  const zipDeliveryId = deliveryModes.find(({ name }) => ['Download', 'Zip'].includes(name))?.id;

  const reportArgInfoForContextLu: { [key: string]: ReportContext } = keyBy(
    reportContexts,
    'context'
  );

  const augmentedTemplates: ReportTemplatesForContext[] = useMemo(
    () => getAugmentedTemplates(contextTemplates, reportArgInfoForContextLu),
    [contextTemplates, reportArgInfoForContextLu]
  );

  const templateZippableLookup = useMemo(() => {
    if (!contextTemplates) return {};
    const templates = flatten(contextTemplates.map(({ templates }) => templates));
    return templates.reduce<Record<string, boolean | undefined>>((acc, { template }) => {
      if (!template) return acc;
      const canZip = template?.deliveryCompatibility?.some(({ id }) => id === zipDeliveryId);
      acc[template.id] = canZip;
      return acc;
    }, {});
  }, [contextTemplates, zipDeliveryId]);

  const {
    data: pollData,
    loading,
    pollInterval,
    pollType,
    setPollType,
    setIdForPolling,
  } = usePollingReportQuery();

  useEffect(() => {
    if (!loading && pollData && pollInterval === 0) {
      switch (pollType) {
        case PollType.REPORT:
          if (pollData?.report?.status === AtlasGqlReportStatus.Failed) {
            message.error({
              key: 'report-loading',
              content: `Report creation failed.`,
              duration: 3,
            });
          } else if (
            pollData?.report?.status === AtlasGqlReportStatus.Succeeded &&
            !!pollData.report.url
          ) {
            const href = pollData.report.url;
            const downloadLink = (
              <a href={href}>
                Your report "{pollData.report.name}" was created. Click to download.
              </a>
            );
            message.success({
              key: 'report-loading',
              content: <>{downloadLink}</>,
              duration: 10,
            });
          }
          return;
        case PollType.DELIVERY:
          if (pollData?.report?.deliveries?.[0].status === AtlasGqlReportDeliveryStatus.Failed) {
            message.error({
              key: 'report-loading',
              content: `Report creation failed.`,
              duration: 3,
            });
          } else if (
            pollData?.report?.deliveries?.[0].status === AtlasGqlReportDeliveryStatus.Succeeded
          ) {
            if (!!pollData.report.deliveries[0].url) {
              const href = pollData.report.deliveries[0].url;
              const downloadLink = <a href={href}>Your reports were created. Click to download.</a>;
              message.success({
                key: 'report-loading',
                content: <>{downloadLink}</>,
                duration: 10,
              });
            } else if (pollData?.report?.deliveries?.[0].deliveryMode?.name === 'Email') {
              message.success({
                key: 'report-loading',
                content: <>Your reports were created and will be emailed to you shortly. </>,
                duration: 10,
              });
            }
          }
      }
    }
  }, [pollData, loading, message, pollInterval, pollType]);

  const cacheUpdate = (cache: any) => {
    cache.evict({
      id: 'ROOT_QUERY',
      field: 'reports',
    });
  };

  const [createReport] = useCreateReportMutation({
    update: cacheUpdate,
    context: {
      retryAttempts: 0,
    },
  });
  const [createReports] = useCreateReportsMutation({
    update: cacheUpdate,
    context: {
      retryAttempts: 0,
    },
  });

  const getReportInputArguments = useCallback(
    ({ templateId }): Array<AtlasGql_CreateReportInput & { waitForCompletion?: boolean }> => {
      const template = (
        flatten(contextTemplates.map(({ templates }) => templates)).find(
          ({ template: { id } }) => id === templateId
        ) || {}
      ).template;
      const { context, longRunning } = template || {};

      const reportArguments = context ? reportArgInfoForContextLu[context]?.reportArguments : [];

      return (reportArguments || []).map(({ args }: { args: Array<AtlasGql_ReportArgument> }) => ({
        reportTemplateId: templateId,
        args,
        // Each report on its own will provide whether it prefers to be long
        // running. We make a decision at the end to let the reports run sync
        // or not.
        waitForCompletion: !longRunning,
      }));
    },
    [contextTemplates, reportArgInfoForContextLu]
  );

  const handleCreateReportTemplates = async ({
    reportTemplates,
    deliveryModeId: inputDeliveryModeId,
    hermesId,
  }: {
    reportTemplates: SubmitReportTemplateProp[];
    deliveryModeId?: string;
    hermesId?: string;
  }) => {
    const reportArguments = flatten(
      (reportTemplates || []).map(({ id: templateId, variantId }) =>
        getReportInputArguments({ templateId }).map(
          (input: AtlasGql_CreateReportInput & { waitForCompletion?: boolean }) => ({
            ...input,
            variantId,
          })
        )
      )
    );
    setModalVisible(false);

    if (!reportArguments.length) {
      // Not sure how to get into this scenario, but figured i'd handle it anyway
      message.success({
        key: 'report-loading',
        content: 'There are no reports to queue ... ',
        duration: 3,
      });
    } else if (reportArguments.length === 1) {
      // Single report creation.
      message.loading({
        key: 'report-loading',
        content: 'Creating Report. You will be notified upon completion.',
        duration: 3,
      });

      try {
        const [input] = reportArguments;

        // shouldPoll = it is not a long running report
        const shouldPoll = input.waitForCompletion && !inputDeliveryModeId;

        const { data } = await createReport({
          variables: assign(
            {
              ...input,
              deliveryModeId: inputDeliveryModeId,
              waitForCompletion: false,
            },
            hermesId
              ? {
                  deliveryModeArgs: [
                    {
                      key: 'hermesId',
                      value: hermesId,
                    },
                  ],
                }
              : {}
          ),
        });
        const report = data?.createReportAsync;
        if (!report) {
          throw new Error('Failed to create report');
        }

        if (shouldPoll) {
          // not long running -> polling
          if (report.id) {
            setPollType(PollType.REPORT);
            setIdForPolling(report.id);
          }
        } else {
          // long running -> no polling
          // The report was queued. Tell the user where to find it when its done.
          const href = `/reports/${report.id}`;
          const here = <a href={href}>here</a>;
          message.success({
            key: 'report-loading',
            content: <>Your report will be available {here} shortly.</>,
            duration: 3,
          });
        }
      } catch (err) {
        message.error({
          key: 'report-loading',
          content: `Report creation failed.`,
          duration: 3,
        });
      }
    } else {
      // Multi report creation.
      message.info({
        key: 'report-loading',
        content: 'Queueing reports for async delivery...',
        duration: 3,
      });

      try {
        // shouldPoll = there are no long running reports
        const shouldPoll = reportArguments.every(r => r?.waitForCompletion);
        const canZip = reportArguments.every(r => templateZippableLookup[r.reportTemplateId]);

        // For multiple reports, we force Zip as the delivery mode unless the
        // user has made an explicit alternate choice via the advanced reports
        // page or if some reports are not zippable.
        const deliveryModeId = inputDeliveryModeId || (canZip ? zipDeliveryId : null);

        const { data } = await createReports({
          variables: assign(
            {
              inputs: reportArguments.map(({ reportTemplateId, variantId, args }) => ({
                reportTemplateId,
                variantId,
                args,
              })),
              deliveryModeId,
              waitForCompletion: false,
            },
            hermesId
              ? {
                  deliveryModeArgs: [
                    {
                      key: 'hermesId',
                      value: hermesId,
                    },
                  ],
                }
              : {}
          ),
        });

        // If we're waiting, we can expect the service to have not returned until
        // the delivery is complete. All reports will share the same delivery, so
        // its fine to just grab the first one.
        const reports = data?.createReportsAsync;
        const firstReport = reports?.[0];
        const delivery = firstReport?.deliveries?.[0];

        if (shouldPoll) {
          if (firstReport?.id) {
            setPollType(PollType.DELIVERY);
            setIdForPolling(firstReport.id);
          }
        } else {
          // At this point, there could be multiple reports being delivered in a
          // single delivery, or just multiple reports.
          let content;
          if (deliveryModeId) {
            const here = <a href={`/report-deliveries/${delivery?.id}`}>here</a>;
            content = (
              <>
                Reports queued successfully.
                {deliveryModeId === zipDeliveryId ? (
                  <>They will be ready {here} shortly</>
                ) : (
                  <>
                    You will be notified when the delivery is complete or you may check on its
                    status {here}
                  </>
                )}
              </>
            );
          } else {
            const here = <a href="/reports">here</a>;
            content = (
              <>
                Reports queued successfully. {!canZip ? <>They cannot be zipped.</> : ''} They will
                be ready {here} shortly.
              </>
            );
          }
          message.success({
            key: 'report-loading',
            content,
            duration: 3,
          });
        }
      } catch (err) {
        message.error({
          key: 'report-loading',
          content: `Report creation failed.`,
          duration: 3,
        });
      }
    }
  };

  return (
    <>
      <Modal
        title="Advanced Report Options"
        visible={modalVisible}
        footer={null}
        onCancel={() => setModalVisible(false)}
        destroyOnClose
      >
        <AdvancedReportForm
          templates={augmentedTemplates}
          onSubmit={handleCreateReportTemplates}
          onCancel={() => setModalVisible(false)}
          cacheKey={cacheKey}
          reportContexts={reportContexts}
        />
      </Modal>
      <Tooltip title={!templatesAreNonEmpty ? 'There are no report templates available' : helpText}>
        <Button _version={4} style={{ padding: '0' }} disabled={disabled || !templatesAreNonEmpty}>
          <Dropdown
            _version={4}
            trigger={['click']}
            overlay={
              <Menu>
                {augmentedTemplates.map(
                  ({ context, templates }) =>
                    templates?.length && (
                      <Menu.ItemGroup title={startCase(lowerCase(context))} key={context}>
                        {templates?.map(({ template, disabled }) => {
                          const { id, name, longRunning, extension, itemLimit } = template;

                          const matchingContext = reportContexts.find(
                            reportContext => reportContext?.context === context
                          );
                          const shouldDisableOption =
                            matchingContext && matchingContext?.optionDisabled;
                          return (
                            <MenuItem
                              key={id}
                              onClick={e =>
                                handleCreateReportTemplates({
                                  reportTemplates: [{ id: e?.key?.toString() }],
                                })
                              }
                              disabled={disabled || shouldDisableOption}
                            >
                              <Space>
                                <Tooltip
                                  title={
                                    disabled
                                      ? matchingContext?.toolTip
                                        ? matchingContext?.toolTip
                                        : `${startCase(
                                            lowerCase(context)
                                          )}:${name} reports are limited to ${itemLimit} items`
                                      : null
                                  }
                                >
                                  {extension && extensionLu[extension]}
                                  &nbsp;
                                  {name}
                                </Tooltip>
                                {longRunning && (
                                  <Tooltip title="This report may take a while to generate. You will be notified when it is complete.">
                                    <Tag color="gold">Async</Tag>
                                  </Tooltip>
                                )}
                              </Space>
                            </MenuItem>
                          );
                        })}
                      </Menu.ItemGroup>
                    )
                )}
                <Menu.Divider />
                <MenuItem onClick={() => setModalVisible(true)}>Advanced</MenuItem>
              </Menu>
            }
          >
            <div style={{ padding: '0 1em' }}>
              Export Report&nbsp;
              <DownOutlined />
            </div>
          </Dropdown>
        </Button>
      </Tooltip>
    </>
  );
}
