import {
  AtlasGqlHorizonDamage,
  AtlasGqlHorizonDamagePropagation,
  AtlasGqlPropagationType,
} from 'types/atlas-graphql';
import { isFinite, orderBy } from 'lodash';
import { ColorCodingAttribute } from './BladeDamagePlotContainer';
import COLORS from 'utils/color/definitions';
import {
  PointProps,
  Circle,
  Square,
  Diamond,
  Triangle,
  POINT_COLORS,
  POINT_SHAPES,
  MORE_OPACITY,
  LESS_OPACITY,
  DEFAULT_OPACITY,
} from './pointShapes';
import { getPrimaryObservationGroupForDamage } from '../utils';

export type ShapeAndColor = {
  ShapeComponent: React.FunctionComponent<PointProps>;
  color: string;
};
export type ColorCodingMap = { [key: string]: { symbol: ShapeAndColor; damageCount?: number } };

export const NullShapeAndColor: ShapeAndColor = {
  ShapeComponent: Diamond,
  color: COLORS.DARK_GRAY,
};

export const severityColorCodingMap: ColorCodingMap = {
  '5': { symbol: { ShapeComponent: Circle, color: COLORS.SEV_MAX_COLOR } },
  '4': { symbol: { ShapeComponent: Square, color: COLORS.SEV_LESS_COLOR } },
  '1-3': { symbol: { ShapeComponent: Triangle, color: COLORS.DARK_GRAY } },
};
const getShapeAndColorForSeverity = (severity?: number): ShapeAndColor => {
  if (!severity) {
    return NullShapeAndColor;
  }
  if (severity >= 5) {
    return severityColorCodingMap['5'].symbol;
  }
  if (severity >= 4) {
    return severityColorCodingMap['4'].symbol;
  }
  return severityColorCodingMap['1-3'].symbol;
};

export const propagationTypeColorCodingMap: ColorCodingMap = Object.fromEntries(
  Object.values(AtlasGqlPropagationType)
    .filter(type => !type.includes('Progression'))
    .map((type, index) => [
      type,
      {
        symbol: {
          ShapeComponent: POINT_SHAPES[index % POINT_SHAPES.length],
          color: POINT_COLORS[index % POINT_COLORS.length],
        },
      },
    ])
);

export const getColorCodingMap = (colorCodingAttributeValues: string[]): ColorCodingMap => {
  let colorIndex = -1;
  let shapeIndex = -1;

  return orderBy(colorCodingAttributeValues, [0]).reduce<ColorCodingMap>((acc, value) => {
    if (!(String(value) in acc)) {
      colorIndex += 1;
      shapeIndex += 1;

      return {
        ...acc,
        [String(value)]: {
          symbol: {
            ShapeComponent: POINT_SHAPES[shapeIndex % POINT_SHAPES.length],
            color: POINT_COLORS[colorIndex % POINT_COLORS.length],
          },
        },
      };
    }
    return acc;
  }, {});
};

/**
 * Given x/y coordinates, damage attributes, and optionally an attribute to color code
 * by with a mapping, returns an svg element representing the damage
 */
const getPointComponent = ({
  key,
  x,
  y,
  colorCodingAttribute,
  colorCodingMap,
  damage,
  propagations,
  selectedDamageIds,
}: {
  key: number; // React key
  x: number;
  y: number;
  colorCodingAttribute?: ColorCodingAttribute;
  colorCodingMap?: ColorCodingMap;
  damage: AtlasGqlHorizonDamage;
  propagations: AtlasGqlHorizonDamagePropagation[];
  selectedDamageIds?: string[];
}): JSX.Element => {
  const { id: damageId, primaryObservationGroupAttrs: attributes } = damage;
  const opacity = selectedDamageIds?.length
    ? selectedDamageIds.includes(damageId)
      ? MORE_OPACITY
      : LESS_OPACITY
    : DEFAULT_OPACITY;
  const pointProps = {
    key,
    damageId,
    x,
    y,
    opacity,
  };

  if (colorCodingAttribute === ColorCodingAttribute.Severity) {
    const { ShapeComponent, color } = getShapeAndColorForSeverity(attributes['Severity']);
    return <ShapeComponent {...pointProps} color={color} />;
  } else if (colorCodingAttribute === ColorCodingAttribute.PropagationType) {
    // const primaryGroupId: string | undefined = getPrimaryObservationGroupForDamage(damage)?.id;
    const primaryGroupPropagationType = propagations?.find(p => p?.damageId === damageId)?.type;
    const { symbol } =
      !!primaryGroupPropagationType && !!colorCodingMap
        ? colorCodingMap?.[primaryGroupPropagationType]
        : { symbol: NullShapeAndColor };
    return <symbol.ShapeComponent {...pointProps} color={symbol.color} />;
  } else if (!!colorCodingAttribute) {
    const { symbol } = colorCodingMap?.[attributes[colorCodingAttribute]] ?? {
      symbol: NullShapeAndColor,
    };
    return <symbol.ShapeComponent {...pointProps} color={symbol.color} />;
  } else {
    return <Circle {...pointProps} color={COLORS.DARK_GRAY} />;
  }
};

/**
 * Map function for getting points for edge damages: given distance, xScale, yScale, and attribute
 * to color code, returns an SVG shape, color-coded if appropriate, at radial distance (x) and 50%
 * chord (y), to show all damages along a single line in the y axis.
 *
 * Damages without distance will return null.
 */
export const mapEdgeDamagesToPoints =
  ({
    xScale,
    yScale,
    colorCodingAttribute,
    colorCodingMap,
    propagations,
    selectedDamageIds,
  }: {
    xScale: d3.ScaleLinear<number, number, never>;
    yScale: d3.ScaleLinear<number, number, never>;
    colorCodingAttribute?: ColorCodingAttribute;
    colorCodingMap?: ColorCodingMap;
    propagations: AtlasGqlHorizonDamagePropagation[];
    selectedDamageIds?: string[];
  }) =>
  (damage: AtlasGqlHorizonDamage, index: number): JSX.Element | null => {
    const { Distance: distance } = damage.primaryObservationGroupAttrs;
    if (isFinite(distance)) {
      return getPointComponent({
        key: index,
        x: xScale(distance),
        y: yScale(50),
        colorCodingAttribute,
        colorCodingMap,
        damage,
        propagations,
        selectedDamageIds,
      });
    }
    return null;
  };

/**
 * Map function for getting points for side damages: given distance, chord, max blade width,
 * blade length, xScale, yScale, and attribute to color code, returns an SVG shape, color-coded
 * if appropriate, at radial distance (x) and chord position (y), projected from the distance
 * and relative blade width at that distance.
 *
 * Damages without distance or chord will return null.
 */
export const mapSideDamagesToPoints =
  ({
    widths,
    maxWidth,
    length,
    xScale,
    yScale,
    colorCodingAttribute,
    colorCodingMap,
    propagations,
    selectedDamageIds,
  }: {
    widths: number[];
    maxWidth: number;
    length: number;
    xScale: d3.ScaleLinear<number, number, never>;
    yScale: d3.ScaleLinear<number, number, never>;
    colorCodingAttribute?: ColorCodingAttribute;
    colorCodingMap?: ColorCodingMap;
    propagations: AtlasGqlHorizonDamagePropagation[];
    selectedDamageIds?: string[];
  }) =>
  (damage: AtlasGqlHorizonDamage, index: number): JSX.Element | null => {
    const { Distance: distance, Chord: chord } = damage.primaryObservationGroupAttrs;

    if (isFinite(distance) && isFinite(chord)) {
      const widthAtDistance = widths[Math.round((100 * distance) / length)];
      const projectedChord = (widthAtDistance / maxWidth) * chord;

      if (isFinite(projectedChord)) {
        return getPointComponent({
          key: index,
          x: xScale(distance),
          y: yScale(projectedChord),
          colorCodingAttribute,
          colorCodingMap,
          damage,
          propagations,
          selectedDamageIds,
        });
      }
    }
    return null;
  };
