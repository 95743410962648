import { useState, useMemo, useEffect, Dispatch, SetStateAction } from 'react';
import { AtlasGqlFilterBy, AtlasGqlTurbine, useGetFleetMapDataQuery } from 'types/atlas-graphql';
import { TTurbineProps } from './types';
import { TurbinePointMap } from './TurbinePointMap';
import FleetMapDetail from 'components/FleetMapDetail';
import { TURBINE_ICON, MARKER_BLUE } from 'utils/constants';
import { TImportedTurbineMappedField } from 'horizon/components/Assets/CreateAssetsModal/types';

interface IFleetMapProps {
  parentId?: string;
  satelliteView?: boolean;
  importedTurbines?: Array<AtlasGqlTurbine>;
  previewMode?: boolean;
  setTurbineToUpdate?: Dispatch<SetStateAction<TImportedTurbineMappedField | undefined>>;
}

/**
 * @property parentId - optional - parent asset (site) uniqe id. Do not use
 *                                  if getting entire fleet map for a given
 *                                  customer or inserting static turbine data.
 * @satelliteView: - optional    - if true, map displays with satellite view
 * @importedTurbine: - optional  - an array of turbines to display on the map
 *                                  instead of requesting turbine data from server
 * @previewMode: - optional      - if true, apply functional and styling changes such
 *                                  as increasing map height
 * @setTurbineToUpdate - optional - if preview mode is true, also send this setter
 *                                  to enable real-time location updates
 *                                  when dragging and dropping pins
 */
export const FleetMap: React.FunctionComponent<IFleetMapProps> = ({
  parentId,
  satelliteView,
  importedTurbines,
  previewMode = false,
  setTurbineToUpdate,
}) => {
  const [turbines, setTurbines] = useState<Array<AtlasGqlTurbine>>(importedTurbines || []);

  useEffect(() => {
    if (importedTurbines) {
      // force update when imported turbines changes to persist changes from
      // map preview drag and drop
      setTurbines(importedTurbines);
    }
  }, [importedTurbines]);

  const contentHeight = previewMode ? '70vh' : '35rem';

  const hasPosition = (x: AtlasGqlTurbine) => x.latLng && x.latLng.length === 2;
  const getTurbineProps = (turbine: AtlasGqlTurbine): TTurbineProps => {
    const [lat, lng] = turbine.latLng as number[];

    return {
      key: turbine.id,
      position: { lat, lng },
      content: <FleetMapDetail turbine={turbine} previewMode={previewMode} />,
      name: turbine.name ?? undefined,
    };
  };
  const filteredTurbines = turbines.filter(hasPosition).map(getTurbineProps);

  const filters: AtlasGqlFilterBy[] = useMemo(
    () =>
      parentId
        ? [
            {
              key: 'siteId',
              values: [parentId],
            },
          ]
        : [],

    [parentId]
  );

  const { loading } = useGetFleetMapDataQuery({
    variables: {
      filterBy: filters,
    },
    skip: !!importedTurbines,
    onCompleted: data => {
      if (data.turbines) {
        setTurbines(data.turbines);
      }
    },
  });

  return (
    // @ts-ignore AN child component is not TS yet
    <TurbinePointMap
      loading={loading}
      items={filteredTurbines}
      icon={previewMode ? MARKER_BLUE : TURBINE_ICON}
      height={contentHeight}
      mapHeight={contentHeight}
      sideList={previewMode ? true : false}
      satelliteView={satelliteView}
      enableClustering
      setTurbineToUpdate={setTurbineToUpdate}
      isDraggable={previewMode ? true : false}
    />
  );
};
