import { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { StatusTag } from 'components/StatusTag';
import { AtlasGqlTurbine } from 'types/atlas-graphql';

const LinkLabel = styled.span`
  font-size: 0.9rem;
  min-width: 4rem;
  margin-right: 0.5rem;
`;

const LinkTitle = styled.span`
  font-size: 0.9rem;
`;

const StaticLabel = styled.span`
  font-size: 0.9rem;
  margin: 0;
`;

const RowContainer = styled.div`
  display: flex;
  margin-bottom: 0.5rem;
`;

interface FleetMapDetailProps {
  turbine: AtlasGqlTurbine | null;
  previewMode?: boolean;
}

const FleetMapDetail: FC<FleetMapDetailProps> = ({ turbine, previewMode }) => {
  const [lat, lng] = turbine?.latLng ?? [];

  return (
    <>
      {turbine?.location && (
        <RowContainer>
          <LinkLabel>Site:</LinkLabel>
          <Link to={`sites/${turbine.location?.id}`}>
            <LinkTitle>{turbine.location?.name}</LinkTitle>
          </Link>
        </RowContainer>
      )}
      {turbine ? (
        <>
          <RowContainer>
            <LinkLabel>Turbine:</LinkLabel>
            {previewMode ? (
              <StaticLabel>{turbine.name}</StaticLabel>
            ) : (
              <Link to={`/turbines/${turbine.id}`}>
                <LinkTitle>{turbine.name}</LinkTitle>
              </Link>
            )}
          </RowContainer>
          <RowContainer>
            <LinkLabel />
            {turbine.status ? <StatusTag value={turbine.status} /> : null}
          </RowContainer>
          {previewMode && (
            <>
              <RowContainer>
                <LinkLabel>Lat:</LinkLabel>
                <StaticLabel>{lat}</StaticLabel>
              </RowContainer>
              <RowContainer>
                <LinkLabel>Lng:</LinkLabel>
                <StaticLabel>{lng}</StaticLabel>
              </RowContainer>
            </>
          )}
        </>
      ) : null}
    </>
  );
};

export default FleetMapDetail;
