import {
  EnvironmentOutlined,
  ProfileOutlined,
  ToolOutlined,
  AlertOutlined,
  DownloadOutlined,
  ClusterOutlined,
  ExperimentOutlined,
  BarChartOutlined,
} from '@ant-design/icons';
import {
  FLEET_TAB_VISIT,
  INSPECTIONS_TAB_VISIT,
  WORK_ORDERS_TAB_VISIT,
  ANALYTICS_TAB_VISIT,
  TASKS_TAB_VISIT,
} from 'utils/access-control/rules';
import { usePermissions } from 'utils/usePermissions';
import { useFeatures } from 'utils/features';
import { Tab } from './types';
import { notEmpty } from 'utils/types';
import { useAccountContext } from 'utils/account/AccountContext';

export function useNavTabs() {
  const { hasReleaseToggle } = useAccountContext();
  const {
    WORK_ORDERS,
    SISENSE,
    ALERT_MANAGER,
    DEVICE_FIRMWARE_MANAGEMENT,
    HORIZON_DAMAGES,
    LEGACY_DAMAGES,
  } = useFeatures().features;

  const { canAccess } = usePermissions();

  const inspectionsLink: string = HORIZON_DAMAGES
    ? '/damages2'
    : LEGACY_DAMAGES
      ? '/damages'
      : '/inspections';

  const tabs: Tab[] = [
    // this is a menu item to test release toggles
    hasReleaseToggle('test_rt')
      ? {
          title: 'Release Toggles',
          icon: <ExperimentOutlined />,
          link: '/',
          id: 'sidebar-rt-button',
          rules: [true],
        }
      : undefined,
    {
      title: 'Fleet',
      icon: <EnvironmentOutlined />,
      link: '/fleet',
      id: 'sidebar-fleet-button',
      rules: [FLEET_TAB_VISIT],
    },
    {
      title: 'Inspections',
      icon: <ProfileOutlined />,
      link: inspectionsLink,
      subRoutes: ['/inspections', '/inspection-uploads'],
      id: 'sidebar-inspections-button',
      rules: [INSPECTIONS_TAB_VISIT],
    },
    {
      title: 'Reports',
      icon: <DownloadOutlined />,
      link: '/reports',
      id: 'sidebar-reports-button',
      rules: [true], // shorthand for anyone with the feature flag can view
    },
    WORK_ORDERS
      ? {
          title: 'Work Orders',
          icon: <ToolOutlined />,
          link: '/work-orders',
          subRoutes: ['/tasks', '/unplanned-tasks', '/campaigns'],
          id: 'sidebar-work-orders-button',
          rules: [WORK_ORDERS_TAB_VISIT],
        }
      : {
          title: 'Tasks',
          icon: <ToolOutlined />,
          link: '/tasks',
          id: 'sidebar-tasks-button',
          rules: [TASKS_TAB_VISIT],
        },
    SISENSE
      ? {
          title: 'Analytics',
          icon: <BarChartOutlined />,
          link: '/analytics',
          id: 'sidebar-analytics-button',
          rules: [ANALYTICS_TAB_VISIT],
        }
      : undefined,
    ALERT_MANAGER
      ? {
          title: 'Alerts',
          icon: <AlertOutlined />,
          link: '/alerts',
          id: 'sidebar-alerts-button',
          rules: [true], // shorthand for anyone with the feature flag can view
        }
      : undefined,
    DEVICE_FIRMWARE_MANAGEMENT
      ? {
          title: 'Device Firmware Management',
          icon: <ClusterOutlined />,
          link: '/device-firmware-management',
          id: 'sidebar-device-firmwares-button',
          rules: [true], // all with feature flag can access
        }
      : undefined,
  ]
    .filter(notEmpty)
    .filter(({ rules }) => canAccess(rules));

  return tabs;
}
