import { Dispatch, useEffect, useState } from 'react';
import { Button, Modal } from 'components/ui';
import { Select } from 'antd5';
import { ColorCodingAttribute, PlotStyleState } from '../BladeDamagePlotContainer';
import { StyleOptionContainer } from './PlotStyleOptionsModal.style';
import { None } from 'components/data/helpers';
import {
  AtlasGqlDamageSchemaAttributeHistoricalString,
  useGetDamageAttributesQuery,
} from 'types/atlas-graphql';

type PlotStyleOptionsModalProps = {
  plotStyleState: PlotStyleState;
  dispatchPlotStyleState: Dispatch<Partial<PlotStyleState>>;
  schemaId?: string;
};

export const PlotStyleOptionsModal: React.FunctionComponent<PlotStyleOptionsModalProps> = ({
  plotStyleState,
  dispatchPlotStyleState,
  schemaId,
}) => {
  const [workingState, setWorkingState] = useState<PlotStyleState>(plotStyleState);
  useEffect(() => {
    setWorkingState(plotStyleState);
  }, [plotStyleState]);

  const { data } = useGetDamageAttributesQuery({
    variables: { getAllSchemaOptionsId: schemaId },
  });

  const filteredColorCodingAttributes = Object.values(ColorCodingAttribute).filter(
    attr =>
      attr === ColorCodingAttribute.PropagationType ||
      data?.getAllSchemaOptions.some(({ name }) => name === attr)
  );

  return (
    <Modal
      open={plotStyleState.modalOpen}
      onCancel={() => dispatchPlotStyleState({ modalOpen: false })}
      title="Plot Style Options"
      footer={
        <div>
          <Button onClick={() => dispatchPlotStyleState({ modalOpen: false })}>Cancel</Button>
          <Button
            type="primary"
            onClick={() => dispatchPlotStyleState({ ...workingState, modalOpen: false })}
          >
            Apply
          </Button>
        </div>
      }
    >
      <StyleOptionContainer>
        <div>
          <p>Color Coding</p>
          <Select
            options={[
              { label: <None />, value: '' },
              ...filteredColorCodingAttributes.map(attribute => ({ value: attribute })),
            ]}
            value={
              workingState.colorCodingAttribute === null ? '' : workingState.colorCodingAttribute
            }
            showSearch={true}
            onChange={(attributeName: ColorCodingAttribute | '') => {
              const attr = data?.getAllSchemaOptions.find(
                attr => 'options' in attr && attr.name === attributeName
              ) as AtlasGqlDamageSchemaAttributeHistoricalString | undefined;

              setWorkingState({
                ...workingState,
                colorCodingAttribute: attributeName === '' ? null : attributeName,
                colorCodingAttributeValues: attr?.options,
              });
            }}
          />
        </div>
      </StyleOptionContainer>
    </Modal>
  );
};
