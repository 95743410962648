import { useLocation } from 'react-router-dom';
import { ShareAltOutlined } from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import styled from 'styled-components';
import { useAccountContext } from 'utils/account/AccountContext';
import { Button, useMessage } from 'components/ui';

const ShareUrlButton = styled(Button)`
  border: none;
  box-shadow: none;
  margin-top: 1.25rem;
  position: relative;

  svg {
    height: 1.25rem;
    width: 1.25rem;
  }
`;

export default function CopyUrlButton() {
  const message = useMessage();
  const { customer, hasReleaseToggle } = useAccountContext();
  const { pathname, search } = useLocation();
  const newUrl = `${window.location.origin}/o/${customer?.id}${pathname}${search}`;

  if (!hasReleaseToggle('show-copy-page-url')) {
    return null;
  }

  return (
    <CopyToClipboard text={newUrl} onCopy={() => message.success('Page URL copied to clipboard')}>
      <ShareUrlButton icon={<ShareAltOutlined />} shape="circle" title="Copy page URL" />
    </CopyToClipboard>
  );
}
