import { ArgsProps } from 'antd5/es/notification/interface';
import { PlotImgContainer, StyledCheckbox } from './FeatureNotification.style';
import { InfoCircleFilled } from '@ant-design/icons';
import COLORS from 'utils/color/definitions';
import {
  getItem as getLocalStorageItem,
  setItem as setLocalStorageItem,
  removeItem as removeLocalStorageItem,
} from 'utils/storage';
import { CheckboxChangeEvent } from 'antd5/es/checkbox';
import PlotImage from './PlotImage.png';
import { useContext, useEffect, useState } from 'react';
import { DataTableContext } from 'components/DataTable';
import { useMessage } from 'components/ui';
import { useGetBladeMakeModelForPlotDemoQuery } from 'types/atlas-graphql';

export const storageKey = 'blade-damage-plot-notification-dismissed';
const toastKey = 'plot-demo-toast';

export const shouldShowBladeDamagePlotFeatureNotification = (): boolean => {
  return getLocalStorageItem(storageKey) !== 'true';
};

type FeatureNotificationProps = {
  onOpenPlot: () => void;
};

const FeatureNotificationContent: React.FunctionComponent<FeatureNotificationProps> = ({
  onOpenPlot,
}) => {
  const message = useMessage(toastKey);
  const { updateFilteredInfo } = useContext(DataTableContext);
  const [toastOpen, setToastOpen] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      setToastOpen(false);
      message.destroy();
    };
  }, []); // intentional emtpy dependency array for cleanup on unmount

  const { data, loading } = useGetBladeMakeModelForPlotDemoQuery();
  const { make, model } = data?.getBladeMakeModelForPlotDemo ?? {};

  const targetFilters = {
    asset: [
      {
        key: 'assetType',
        value: ['blade'],
      },
      {
        key: 'assetMake',
        value: [make],
      },
      {
        key: 'assetModel',
        value: [model],
      },
    ],
  };

  const handleSeeExample = () => {
    updateFilteredInfo(targetFilters);
    onOpenPlot();

    if (!toastOpen) {
      setToastOpen(true);
      message.withClose({
        content: (
          <span>
            You're seeing damages for {make} {model} blades, color coded by Severity
          </span>
        ),
        onClose: () => setToastOpen(false),
      });
    }
  };

  const handleTogglePermanentDismissal = (event: CheckboxChangeEvent) => {
    if (event.target.checked) {
      setLocalStorageItem(storageKey, 'true');
    } else {
      removeLocalStorageItem(storageKey);
    }
  };

  return (
    <>
      <PlotImgContainer>
        <img src={PlotImage} alt="Damage Plot" />
      </PlotImgContainer>
      <p>
        Now you can Plot Damages from your entire fleet, by make and model, on a single diagram. For
        detailed information about this feature,
        <br />
        click{' '}
        <a
          href="https://guide.skyspecs.com/horizon/-MPobGVTLxgvA9kxtck7/viewing-images/blade-damage-plot"
          target="_blank"
          rel="noreferrer"
        >
          HERE
        </a>
        .
      </p>
      {!loading &&
        (!!make && !!model ? (
          <p>
            To see an example plot with your data,
            <br />
            click <a onClick={handleSeeExample}>HERE</a>.
          </p>
        ) : (
          <p>
            The data you have access to does not have any standard and complete Asset information -
            reach out to <a href="mailto:support@skyspecs.com">support@skyspecs.com</a> for further
            help.
          </p>
        ))}
      <StyledCheckbox onChange={handleTogglePermanentDismissal}>Don't show again</StyledCheckbox>
    </>
  );
};

export const getBladeDamagePlotFeatureNotificationConfig = ({
  onOpenPlot,
}: FeatureNotificationProps): ArgsProps => ({
  message: 'Explore Blade Damage Analytics Now!',
  icon: <InfoCircleFilled style={{ color: COLORS.TEAL }} />,
  description: <FeatureNotificationContent onOpenPlot={onOpenPlot} />,
  duration: 0,
  placement: 'bottomRight',
});
