import { useState, useEffect, useContext, useCallback } from 'react';
import { isMatch, pickBy } from 'lodash';

import { DataTableContext } from './index';

export function usePresetFilters({ initialPresetFilter, tabList = [], deprecatedOnTabChange }) {
  const { filteredInfo, sortedInfo, updateFilteredInfo, updateSortedInfo } =
    useContext(DataTableContext);

  const { key: defaultActiveTabKey } = initialPresetFilter || {};

  const getTabByKey = useCallback(key => tabList.find(t => t.key === key), [tabList]);

  const getTabByValue = useCallback(
    value => {
      return tabList.find(({ value: tabValue }) => {
        if (tabValue) {
          //find match with special case of both are empty.  cant match against the pickby because it will always be true
          return (
            isMatch(value.filters, tabValue.filters) ||
            (Object.keys(value.filters).length === 0 &&
              Object.keys(pickBy(tabValue.filters, v => v !== undefined)).length === 0)
          );
        }
        return false;
      });
    },
    [tabList]
  );

  // try to default the tab selection based on filter / sort situation, fall back to defaultActiveTabKey
  const { key: matchedTabKey } =
    getTabByValue({ filters: filteredInfo, sortOrder: sortedInfo }) || {};

  const defaultKey = matchedTabKey ? matchedTabKey : defaultActiveTabKey;

  const [activeTab, setActiveTab] = useState(defaultKey ? { key: defaultKey } : {});

  const updateFilterAndSortFromTab = useCallback(
    incomingKey => {
      const { value = {} } = getTabByKey(incomingKey) || {};
      const { filters: presetFilterValue = {}, sortOrder: presetSortOrderValue = {} } = value;
      const { value: prevValue = {} } = getTabByKey(activeTab?.key) || {};
      // If there is a value associated with the previous tab, we need to clear out the sort/filter defined by that tab first
      if (prevValue && !isMatch(prevValue, value)) {
        const { filters: previousFilterValue = {}, sortOrder: previousSortOrderValue = {} } =
          prevValue;
        const clearedFilter = pickBy(
          filteredInfo,
          // Have to call toString in order to correctly compare contents of potential arrays
          (value, key) => previousFilterValue[key]?.toString() !== value?.toString()
        );
        const clearedSort = pickBy(
          sortedInfo,
          (value, key) => previousSortOrderValue[key]?.toString() !== value?.toString()
        );
        updateFilteredInfo({ ...clearedFilter, ...presetFilterValue });
        updateSortedInfo({ ...clearedSort, ...presetSortOrderValue });
      } else {
        updateFilteredInfo({ ...filteredInfo, ...presetFilterValue });
        updateSortedInfo({ ...sortedInfo, ...presetSortOrderValue });
      }
    },
    // Do NOT include activeTab in this dependency array. It will prevent switching between non-all tabs from working properly
    [getTabByKey, updateFilteredInfo, updateSortedInfo, filteredInfo, sortedInfo]
  );

  useEffect(
    () => {
      const { key, isClickEvent } = activeTab || {};
      if (isClickEvent) {
        updateFilterAndSortFromTab(key);
      }
    },
    // updateFilterAndSortFromTab is intentionally omitted from dependencies since it would create an infinite loop of this effect and the callback forcing each other to run - RL, 11/30/22
    [activeTab]
  );

  useEffect(() => {
    const { key } = getTabByValue({ filters: filteredInfo, sortOrder: sortedInfo }) || {};
    if (key) {
      setActiveTab({ key });
    }
  }, [filteredInfo, sortedInfo, getTabByValue]);

  function onTabChange(key) {
    if (deprecatedOnTabChange) {
      setActiveTab({ key });
      deprecatedOnTabChange(key);
      return;
    }
    setActiveTab({ key, isClickEvent: true });
  }

  return {
    activeTabKey: activeTab ? activeTab.key : undefined,
    onTabChange,
  };
}
