import { useEffect, useMemo, useState } from 'react';
import { useStandardFields } from '../useStandardFields';
import { isEqual } from 'lodash';
import { getContextualColumns, sortAndMapFieldsToColumns } from './utils';
import { useFeatures } from 'utils/features';
import GET_WORK_CONTAINER_VALUES from 'horizon/routes/Settings/WorkContainerFields/queries/GetWorkContainerValues.atlas.gql';
import {
  useSaveWorkContainerFieldValuesMutation,
  useGetWorkContainerFieldsQuery,
  useGetWorkContainerValuesLazyQuery,
  AtlasGqlWorkContainerTypeOption,
  AtlasGqlTask,
} from 'types/atlas-graphql';
import { usePermissions } from 'utils/usePermissions';
import { useAccountContext } from 'utils/account/AccountContext';
import { notEmpty } from 'utils/types';
import { deconstructAtlasTask } from 'horizon/routes/WorkOrders/Tasks/TaskDetail/helpers';
import { useOrganizationDeliveryCustomers } from 'utils/organization';
import { useMessage } from 'components/ui';

type UseHeaderFieldsProps = {
  containerId: string;
  type: AtlasGqlWorkContainerTypeOption | string;
  // Array of fields with values pulled directly off of the work container. If undefined, the hook will attempt to query for the field values separately
  additionalFields?: any[];
  // FIXME JDJ: This could eventually be used to replace both containerId and additional fields
  // But that's a large refactor.
  workContainer?: AtlasGqlTask | undefined | null;
  // This component uses a combination of both rules and the isEditButtonVisible prop
  // to determine whether to show the edit button.
  // This is to continue to deprecate the use of rules going forward but need a way
  // to dynamically show the button for ongoing work. - AN 9/27/23
  isEditButtonVisible?: boolean | undefined;
  // Optional callback. Pass this in if an editable field needs to call the edit task function directly
  onSave?: (input: any) => Promise<void> | undefined;
};

// Fetches and formats the header fields for a given work container
// If no work container is given, fetches and formats the header fields for the given work contianer type
export function useHeaderFields({
  additionalFields,
  containerId,
  isEditButtonVisible,
  onSave,
  type,
  workContainer,
}: UseHeaderFieldsProps) {
  const message = useMessage();
  const { features } = useFeatures();
  const { hasReleaseToggle } = useAccountContext();
  const hasVendorRT = hasReleaseToggle('vendor-dropdown');
  const { FOR_ORGANIZATION_EDIT } = features;

  const { hasRole } = usePermissions();
  const isEditor = hasRole('role-editor');

  const [currentFields, setCurrentFields] = useState<any[]>([]);
  const [mergedAdditionalFields, setMergedAdditionalFields] = useState<any[]>([]);
  const [fetchedAdditionalFields, setFetchedAdditionalFields] = useState<any[]>([]);
  const [getWorkContainerValues, { data }] = useGetWorkContainerValuesLazyQuery();

  const workFieldsResult = useGetWorkContainerFieldsQuery({
    variables: { type: type as AtlasGqlWorkContainerTypeOption },
    skip: !type,
  });

  const [saveWorkContainerFieldValues, { loading }] = useSaveWorkContainerFieldValuesMutation({
    refetchQueries: [{ query: GET_WORK_CONTAINER_VALUES, variables: { containerId } }],
    awaitRefetchQueries: true,
  });

  const isForOrgSet = workContainer && 'forOrg' in workContainer && workContainer.forOrg;

  const { deliveryCustomers } = useOrganizationDeliveryCustomers({
    skip: !(workContainer && (isForOrgSet || isEditor || FOR_ORGANIZATION_EDIT)),
  });
  const standardFields = useStandardFields(containerId, isEditButtonVisible);

  useEffect(() => {
    if (data?.task?.additionalFields) setFetchedAdditionalFields(data.task.additionalFields);
  }, [data]);

  useEffect(() => {
    if (!additionalFields && containerId) {
      getWorkContainerValues({ variables: { containerId } });
    } else if (additionalFields?.length && additionalFields.length > 0) {
      setMergedAdditionalFields(additionalFields);
    }
  }, [additionalFields, containerId, getWorkContainerValues]);

  useEffect(() => {
    if (fetchedAdditionalFields && fetchedAdditionalFields.length > 0) {
      setMergedAdditionalFields(fetchedAdditionalFields);
    }
  }, [fetchedAdditionalFields]);

  useEffect(() => {
    if (!workFieldsResult) {
      message.error('Unable to fetch Task data');
    }
  }, [message, workFieldsResult]);

  const workContainerFields = useMemo(
    () => workFieldsResult?.data?.workContainerFields ?? [],
    [workFieldsResult?.data?.workContainerFields]
  );
  const allFieldsLoading = workFieldsResult?.loading ?? false;

  useEffect(() => {
    if ((mergedAdditionalFields.length || workContainerFields.length) && !loading) {
      const { vendorOrganization } = deconstructAtlasTask(workContainer);

      const allFieldsAndValues = workContainerFields
        .map(field => {
          const fieldWithData = mergedAdditionalFields.find(aField => aField.id === field.id);
          const aField = { ...field, ...fieldWithData };

          if (aField.slug === 'vendor' && aField.standard) {
            if (vendorOrganization && hasVendorRT) {
              // when the vendor org is set, do not show the legacy vendor
              return null;
            }

            return {
              ...aField,
              name: hasVendorRT ? 'Vendor (Legacy)' : 'Vendor',
            };
          }

          if (!hasVendorRT && aField.slug === 'vendor_organization' && aField.standard) {
            // don't display the new vendor organization field when the user
            // doesn't have the release toggle
            return null;
          }

          return aField;
        })
        .filter(notEmpty);

      if (!isEqual(currentFields, allFieldsAndValues)) {
        setCurrentFields(allFieldsAndValues);
      }
    }
  }, [
    currentFields,
    hasVendorRT,
    loading,
    mergedAdditionalFields,
    workContainer,
    workContainerFields,
  ]);

  const workContainerColumns = sortAndMapFieldsToColumns(
    [...currentFields],
    type,
    standardFields,
    containerId,
    saveWorkContainerFieldValues,
    isEditButtonVisible
  );

  const contextualColumns = useMemo(() => {
    if (!workContainer) return [];
    return getContextualColumns(workContainer, isEditor, features, deliveryCustomers, onSave);
  }, [features, isEditor, onSave, deliveryCustomers, workContainer]);

  const finalColumns = [...workContainerColumns, ...contextualColumns];

  return {
    workContainerColumns: finalColumns,
    fieldsLoading: allFieldsLoading,
  };
}
