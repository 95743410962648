import { useState } from 'react';
import { isEmpty, isNil, isBoolean } from 'lodash';
import moment from 'moment';
import { Modal } from 'antd';

import { BulkEdit } from 'utils/editable';
import { getPrimaryObservationGroupForDamage } from 'horizon/components/Damages/utils';

import {
  AtlasGqlHorizonDamage,
  useUpdateHorizonDamageObservationGroupMutation,
  useSetHorizonDamageConfirmationStatusMutation,
} from 'types/atlas-graphql';
import { TEditColumnData } from 'components/data/types';

interface DamagesBulkEditProps {
  selected: AtlasGqlHorizonDamage[];
  columns: TEditColumnData[];
  onFinish: () => void;
}

export const DamagesBulkEdit: React.FunctionComponent<DamagesBulkEditProps> = ({
  selected,
  columns,
  onFinish,
}) => {
  const [values, setValues] = useState<{ [key: string]: any }>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalText, setModalText] = useState<string>('');

  const [updateGroupAttributes] = useUpdateHorizonDamageObservationGroupMutation({
    update: (cache, { data }) => {
      const updatedGroup = data?.updateHorizonDamageObservationGroup;
      if (
        updatedGroup?.isPrimaryObservationGroup === true &&
        updatedGroup?.damageId &&
        updatedGroup?.groupAttrs
      ) {
        cache.modify({
          id: `HorizonDamage:${updatedGroup.damageId}`,
          fields: {
            primaryObservationGroupAttrs(currentAttrs) {
              return updatedGroup.groupAttrs;
            },
          },
        });
      }
    },
  });

  const [setConfirmationStatus] = useSetHorizonDamageConfirmationStatusMutation();

  const handleSubmit = async (values: { [key: string]: any }) => {
    const groupsWithOneObservation = selected.filter(damage => {
      const primaryObservationGroup = getPrimaryObservationGroupForDamage(damage);
      return primaryObservationGroup?.observations?.length === 1;
    });

    if (groupsWithOneObservation.length === selected.length) {
      // all groups have 1 observation
      await handleBulkEdit(values);
    } else {
      if (groupsWithOneObservation.length === 0) {
        // all groups have > 1 observation
        setModalText(
          'This action will apply to Damages with multiple Observations ' +
            'in their Primary Observation Group. For those Damages, the action ' +
            'will set attributes at the Observation Group level without changing ' +
            'the Observation attributes.'
        );
      } else {
        // groups have a mix of observations numbers
        setModalText(
          'This action will apply to multiple Damages, some of which have ' +
            'multiple Observations in their Primary Observation Groups. For ' +
            'those Damages, this action will set attributes at the Observation Group ' +
            'level without changing the attributes of the component Observations.'
        );
      }

      setValues(values);
      setModalOpen(true);
    }
  };

  const handleBulkEdit = async (values: { [key: string]: any }) => {
    const { confirmationStatus, critical, ...attrs } = values;

    setIsLoading(true);
    await Promise.all([
      // bulk edit mutations for confirmation status
      ...(confirmationStatus
        ? selected.map(damage => {
            return setConfirmationStatus({
              variables: {
                input: {
                  id: damage.id,
                  confirmationStatus,
                },
              },
            });
          })
        : []),
      // bulk edit mutations for attributes/critical
      ...(!isEmpty(attrs) || isBoolean(critical)
        ? selected.map(damage => {
            const primaryObservationGroup = getPrimaryObservationGroupForDamage(damage);

            if (primaryObservationGroup) {
              const groupAttrs = Object.fromEntries(
                Object.entries({ ...primaryObservationGroup?.groupAttrs, ...attrs })
                  .map(([key, value]: [key: string, value: any]) => {
                    if (typeof value === 'object' && 'toDate' in value) {
                      return [key, value.format('YYYY-MM-DD')];
                    }

                    return [key, value];
                  })
                  .filter(
                    ([key, value]) =>
                      (typeof value === 'string' && !isEmpty(value)) ||
                      (typeof value === 'number' && isFinite(value))
                  )
              );

              return updateGroupAttributes({
                variables: {
                  input: {
                    id: primaryObservationGroup.id,
                    ...(!isEmpty(attrs) ? { groupAttrs } : {}),
                    ...(isBoolean(critical) ? { groupCritical: critical } : {}),
                  },
                },
              });
            }

            return Promise.resolve();
          })
        : []),
    ]);

    setIsLoading(false);
    setModalOpen(false);

    onFinish();
  };

  const EDIT_DAMAGES = { min: 1, max: 200 };

  return (
    <>
      <Modal
        visible={modalOpen}
        title={'Confirm Observation Group Bulk Editing'}
        okText={'Confirm'}
        cancelText={'Cancel'}
        onOk={async () => {
          await handleBulkEdit(values);
        }}
        onCancel={() => {
          setModalOpen(false);
        }}
        confirmLoading={isLoading}
        destroyOnClose
      >
        {modalText}
      </Modal>
      <BulkEdit
        records={selected.map(damage => {
          const { id, confirmationStatus } = damage;
          const { groupAttrs: attrs, groupCritical: critical } =
            getPrimaryObservationGroupForDamage(damage) ?? {};

          return {
            id,
            confirmationStatus,
            critical,
            ...attrs,
          };
        })}
        columns={columns.map(column => ({
          ...column,
          getInitialValue: (record: any) => {
            const value = record?.[column.key];
            const type: string | undefined = 'type' in column ? (column.type as string) : undefined;

            if (!isNil(value)) {
              switch (type) {
                case 'string':
                case 'number':
                  return value;
                case 'date':
                  return moment(value);
                default:
                  return value;
              }
            }

            return undefined;
          },
        }))}
        onSubmit={handleSubmit}
        disabled={selected.length < EDIT_DAMAGES.min || selected.length > EDIT_DAMAGES.max}
        disabledMessage={
          selected.length < EDIT_DAMAGES.min || selected.length > EDIT_DAMAGES.max
            ? `Select from ${EDIT_DAMAGES.min} to ${EDIT_DAMAGES.max} damages to bulk edit.`
            : ''
        }
      />
    </>
  );
};
