import { useEffect, useState } from 'react';
import { Form, Select } from 'antd';
import { Button, ModalActionButtons, MultiLineErrorMessage, useMessage } from 'components/ui';
import { WorkContainerChooser } from 'components/choosers/WorkContainerChooser';
import { WORK_ORDER } from 'utils/constants';
import {
  AtlasGqlWorkContainerTypeOption,
  useCreateTasksMutation,
  useCreateTasksFromTemplateMutation,
  AtlasGqlDamage,
  AtlasGqlHorizonDamage,
  AtlasGqlCreateTaskFromTemplateMutation,
  AtlasGqlCreateTaskMutation,
  AtlasGqlDamageRepairTask,
  AtlasGqlDamageInspectTask,
  AtlasGqlCreateTasksFromTemplateMutation,
  AtlasGqlCreateTasksMutation,
} from 'types/atlas-graphql';
import { TaskTemplatesChooser } from 'horizon/routes/WorkOrders/Tasks/TaskTemplates/TaskTemplatesChoosers';
import {
  formatDamageId,
  getPrimaryObservationForDamage,
  getPrimaryObservationGroupForDamage,
} from 'horizon/components/Damages/utils';
import { ApolloCache, useApolloContext } from 'utils/apollo';
import { GraphQLError } from 'graphql';
import { getTaskTargetInput } from 'components/CreateTask/helpers';

interface ICreateTasksFromDamagesForm {
  damages: (AtlasGqlHorizonDamage | AtlasGqlDamage)[];
  open: boolean;
  setOpen: (open: boolean) => void;
}

type FormValues = {
  taskType:
    | AtlasGqlWorkContainerTypeOption.RepairTask
    | AtlasGqlWorkContainerTypeOption.InspectTask;
  taskTemplate: string | undefined;
  workOrder: string | undefined;
};

type AtlasGqlDamageTaskTypes = AtlasGqlDamageRepairTask | AtlasGqlDamageInspectTask;

export const CreateTasksFromDamagesForm: React.FC<ICreateTasksFromDamagesForm> = ({
  damages = [],
  open,
  setOpen,
}) => {
  const message = useMessage();
  const { papiClient } = useApolloContext();
  const [disabled, setDisabled] = useState<boolean>(true);
  const [taskType, setTaskType] = useState<AtlasGqlWorkContainerTypeOption[]>([]);
  const [form] = Form.useForm();
  const messageKeySuccess = 'createTasksFromDamagesSuccessMessage';
  const messageKeyError = 'createTasksFromDamagesErrorMessage';
  const isHorizonDamage = damages[0]?.__typename === 'HorizonDamage';

  const handleReset = () => {
    form.resetFields();
    setDisabled(true);
    setTaskType([]);
    setOpen(false);
  };

  const messageContent = ({ type, count }: { type: string; count: number }) => {
    const workOrderId: string | undefined = form.getFieldValue('workOrder');
    const typeLabel = `${
      type === AtlasGqlWorkContainerTypeOption.RepairTask ? 'Repair task' : 'Inspect task'
    }${count > 1 ? 's' : ''}`;
    return (
      <a
        id="task-create-success-message"
        href={workOrderId ? `/work-orders/${workOrderId}` : '/unplanned-tasks'}
      >
        {count} {typeLabel} created. Click to View.
      </a>
    );
  };

  const handleComplete = (
    data:
      | AtlasGqlCreateTasksFromTemplateMutation['createTasksFromTemplate']
      | AtlasGqlCreateTasksMutation['createTasks']
  ) => {
    // Filter out any null values in data
    const dataLength = data?.filter(Boolean).length;
    if (dataLength) {
      const type = form.getFieldValue('taskType');
      message.success({
        content: messageContent({ type, count: dataLength }),
        key: messageKeySuccess,
        duration: 3,
      });

      papiClient.resetStore();
    } else {
      message.success({
        content: 'Creating Tasks finished, but there was an issue returning the data.',
        key: messageKeySuccess,
        duration: 3,
      });
    }
    handleReset();
  };

  const handleError = (errors: readonly GraphQLError[]) => {
    const damageNumbers: string[] = [];

    let errorMessage = <p>There was an issue with creating Tasks</p>;

    errors.map((err: GraphQLError) => {
      // We should be able to pull the number of the damage as well. Check for it first though:
      const damageId = isHorizonDamage ? err.extensions?.horizonDamageId : err.extensions?.damageId;
      const selectedDamage = damages.find(damage => damage.id === damageId);

      if (selectedDamage) {
        const damageNumber = isHorizonDamage
          ? formatDamageId(selectedDamage as AtlasGqlHorizonDamage)
          : (selectedDamage as AtlasGqlDamage)?.number;

        if (damageNumber) {
          damageNumbers.push(damageNumber);
        }
      }
    });

    if (damageNumbers.length > 0) {
      errorMessage = MultiLineErrorMessage({
        messageContent: `There was an issue creating Tasks for the following Damages: ${damageNumbers.join(
          ', '
        )}`,
      });
    }

    message.error({
      content: errorMessage,
      key: messageKeyError,
      duration: 0,
      style: {
        maxWidth: '600px',
        margin: 'auto',
      },
      icon: <></>,
      className: 'create-task-error-message',
    });

    handleReset();
  };

  useEffect(() => {
    // When modal is closed by clicking outside of it reset the form
    return () => {
      if (!open) form.resetFields();
    };
  }, [open]);

  const handleWriteFragment = (
    cache: ApolloCache<AtlasGqlCreateTaskFromTemplateMutation | AtlasGqlCreateTaskMutation>,
    data: AtlasGqlDamageTaskTypes[]
  ) => {
    data.forEach(task => {
      const taskId = `${task.__typename}:${task.id}`;
      const damageId = isHorizonDamage
        ? `${task.horizonDamage?.__typename}:${task.horizonDamage?.id}`
        : `${task.damage.__typename}:${task.damage.id}`;

      // Append task ref to the damage ID's task array
      cache.modify({
        id: damageId,
        fields: {
          tasks(cachedTasks: AtlasGqlDamageTaskTypes[]) {
            return [...cachedTasks, { _ref: taskId }];
          },
        },
      });
    });
  };

  const [
    createTasksFromTemplate,
    {
      data: createTasksFromTemplateData,
      error: createTasksFromTemplateError,
      loading: createTasksFromTemplateLoading,
    },
  ] = useCreateTasksFromTemplateMutation({
    errorPolicy: 'all',
  });

  // Due to how partial successes WITH errors works we need to handle completed/errors within this useEffect:
  useEffect(() => {
    if (!createTasksFromTemplateLoading) {
      if (createTasksFromTemplateData?.createTasksFromTemplate) {
        handleComplete(createTasksFromTemplateData.createTasksFromTemplate);
      }
      if (createTasksFromTemplateError) {
        handleError(createTasksFromTemplateError.graphQLErrors);
      }
    }
  }, [createTasksFromTemplateLoading, createTasksFromTemplateData, createTasksFromTemplateError]);

  const [
    createTasks,
    { data: createTasksData, error: createTasksError, loading: createTasksLoading },
  ] = useCreateTasksMutation({
    errorPolicy: 'all',
  });

  // Due to how partial successes WITH errors works we need to handle completed/errors within this useEffect:
  useEffect(() => {
    if (!createTasksLoading) {
      if (createTasksData?.createTasks) {
        handleComplete(createTasksData.createTasks);
      }
      if (createTasksError) {
        handleError(createTasksError.graphQLErrors);
      }
    }
  }, [createTasksLoading, createTasksData, createTasksError]);

  const onFinish = async (values: FormValues) => {
    setDisabled(true);
    message.loading({ content: 'Creating tasks...', key: messageKeySuccess, duration: 0 });
    const { taskType, workOrder, taskTemplate } = values;

    const damagesInputs = damages.map(damage => {
      const primaryLegacyDamageBranchId = isHorizonDamage
        ? getPrimaryObservationForDamage(damage as AtlasGqlHorizonDamage)?.legacyDamageBranchId
        : null;
      const inspectionId = isHorizonDamage
        ? getPrimaryObservationGroupForDamage(damage as AtlasGqlHorizonDamage)?.inspectionId
        : null;

      return {
        ...(isHorizonDamage
          ? {
              damageId: primaryLegacyDamageBranchId ?? undefined,
              damageBranchId: primaryLegacyDamageBranchId ?? undefined,
              horizonDamageId: damage.id,
              targets: getTaskTargetInput({ inspectionId, horizonDamageId: damage.id }),
            }
          : { damageId: damage.id, damageBranchId: (damage as AtlasGqlDamage).branchGlobalId }),
        assetId: damage.assetId ?? damage.asset?.id,
        parentId: workOrder ? workOrder : null,
      };
    });

    if (taskTemplate) {
      await createTasksFromTemplate({
        variables: {
          templateId: taskTemplate,
          inputs: damagesInputs,
        },
        update: (cache, { data }) => {
          if (data?.createTasksFromTemplate) {
            const { createTasksFromTemplate } = data;
            // Errors on individual tasks will create nulls.
            handleWriteFragment(
              cache,
              createTasksFromTemplate.filter(Boolean) as AtlasGqlDamageTaskTypes[]
            );
          }
        },
      });
    } else {
      await createTasks({
        variables: {
          inputs: damagesInputs.map(input => ({ ...input, type: taskType })),
        },
        update: (cache, { data }) => {
          if (data?.createTasks) {
            const { createTasks } = data;
            // Errors on individual tasks will create nulls within our data.
            handleWriteFragment(cache, createTasks.filter(Boolean) as AtlasGqlDamageTaskTypes[]);
          }
        },
      });
    }
  };

  const onValuesChange = (changedValues: FormValues) => {
    if (changedValues.taskType) {
      setDisabled(false);
      setTaskType([changedValues.taskType]);
    }
  };

  return (
    <Form
      name="createTasksOnDamages"
      form={form}
      layout="vertical"
      initialValues={{ taskType: undefined, taskTemplate: undefined, workOrder: undefined }}
      onFinish={onFinish}
      onValuesChange={onValuesChange}
    >
      <Form.Item
        name="taskType"
        label="Task Type"
        rules={[{ required: true, message: 'Please select a task type.' }]}
      >
        <Select placeholder="Select a Task Type">
          <Select.Option value={AtlasGqlWorkContainerTypeOption.RepairTask}>Repair</Select.Option>
          <Select.Option value={AtlasGqlWorkContainerTypeOption.InspectTask}>
            Inspect Task
          </Select.Option>
        </Select>
      </Form.Item>
      <Form.Item name="taskTemplate" label="Task Template">
        <TaskTemplatesChooser
          displayType="select"
          onClickHandler={(templateId: string) => form.setFieldsValue({ taskTemplate: templateId })}
          workContainerTypes={taskType}
          disabled={Boolean(!taskType.length)}
        />
      </Form.Item>
      <Form.Item name="workOrder" label="Work Order">
        <WorkContainerChooser
          type={WORK_ORDER}
          allowClear={true}
          containerId="damages-work-order-chooser"
        />
      </Form.Item>
      <ModalActionButtons>
        <Button _version={4} onClick={handleReset}>
          Cancel
        </Button>
        <Button _version={4} type="primary" htmlType="submit" disabled={disabled}>
          Create
        </Button>
      </ModalActionButtons>
    </Form>
  );
};
