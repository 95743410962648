import { Popover } from 'antd5';
import styled from 'styled-components';
import COLORS from 'utils/color/definitions';

export const LegendPopover = styled(Popover)<{
  $isScrolledToTop: boolean;
  $isScrolledToBottom: boolean;
}>`
  .ant-popover-content {
    width: max-content;

    .ant-popover-inner {
      padding: 0;

      ${({ $isScrolledToTop }) =>
        !$isScrolledToTop &&
        `
            -webkit-box-shadow: inset 0px 5px 5px -3px rgba(0, 0, 0, 0.2);
            -moz-box-shadow: inset 0px 5px 5px -3px rgba(0, 0, 0, 0.2);
            box-shadow: inset 0px 5px 5px -3px rgba(0, 0, 0, 0.2);
          `}

      .ant-popover-inner-content {
        max-height: 400px;
        overflow-y: auto;
        padding: 0;

        > div {
          ul {
            list-style-type: none;
            margin-bottom: 0;
            padding: 1rem;

            span {
              margin-left: 0.5rem;
            }
          }
        }

        ${({ $isScrolledToBottom }) =>
          !$isScrolledToBottom &&
          `
          -webkit-box-shadow: inset 0px -5px 5px -3px rgba(0, 0, 0, 0.2) !important;
          -moz-box-shadow: inset 0px -5px 5px -3px rgba(0, 0, 0, 0.2) !important;
          box-shadow: inset 0px -5px 5px -3px rgba(0, 0, 0, 0.2) !important;
        `}
      }
    }
  }
`;

export const DamageCountSpan = styled.span`
  color: ${COLORS.DARK_GRAY};
  margin-left: 0 !important;
`;
