import { useEffect } from 'react';
import { useMessage } from 'components/ui';

/**
 * This hook will alert the user when signed image URLs have expired. Only the
 * "expires at" value needs to be provided in order to notify.
 *
 * @param {number|null|undefined} expiresAt In seconds
 */
export function useSignedUrlsExpiredMessaging(expiresAt: number | null | undefined) {
  const message = useMessage();

  useEffect(() => {
    if (!expiresAt) {
      return;
    }

    const timeout = Math.max(0, expiresAt * 1000 - Date.now());
    const timeoutId = window.setTimeout(() => {
      message.error({
        key: 'image-viewing-session-expired',
        content: 'Image viewing session has expired. Please refresh the page.',
        duration: 0,
      });
    }, timeout);

    return () => {
      message.destroy('image-viewing-session-expired');
      if (timeoutId) {
        window.clearTimeout(timeoutId);
      }
    };
  }, [expiresAt, message]);
}
